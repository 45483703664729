<template>
   <section class="checkout-section room-info-section" :class="!rooms.length ? 'data-mask' : ''">
      <div id="validation-error-auto-scroller"></div>
      <div class="checkout-section-inner room-info-card">
         <div class="checkout-section-title masked">
            <h4>{{ $t("hotelCheckout_lblRoomInfo") }} - {{ hotelName }}</h4>
         </div>
         <!-- <div class="guest-informations-header-wrapper">
            <span class="section-title">{{ $t("hotelGuestInformationsComponent_guestInformations") }}</span>
            <div id="autofill-wrapper" v-if="ShowAutoFill">
               <span class="autofill-highlighter"> {{ $t("flight_fillPaxRowsTooltipHighlighter") }}</span>
               <button id="fill-form" @click="handlerShowModal">
                  {{ $t("flight_fillPaxRowsTooltip") }}
               </button>
               <button id="clear-form" @click="handlerClearForm">
                  <i class="gts-icon icon-hotel-empty-basket icon-sm"></i>
               </button>
            </div>
         </div> -->
         <RoomInfoCardSkeleton v-if="!rooms.length" />
         <RoomInfoCard
            @handleUpdateAddons="handleUpdateAddons"
            :searchedRoom="roomsList"
            v-for="(roomItem, index) in roomsList"
            :key="index"
            :roomIndex="index"
            :room="roomItem"
            :contactModel="contactModel"
            :customerNationality="customerNationality"
            :lastBookingDate="lastBookingDate"
            :checkinDate="checkinDate"
            :checkoutDate="checkoutDate" />
         <!-- <UIModal v-show="showModal" :noBorder="true" :hasSlot="true" @close-modal="showModal = false">
            <div class="traveler-data-form">
               <div class="transfer-manuel-request-info-wrapper col-12">
                  <div class="traveler-data-form__title">
                     <i class="gts-icon icon-popup-cursor"></i>
                     <h3 class="txt-black">{{ $t("transfer_fillTravelers") }}</h3>
                  </div>
                  <div class="traveler-data-form__content">
                     <p>{{ $t("transfer_fillTravelersContent") }}</p>
                     <div class="traveler-data-form__info-wrapper">
                        <div class="traveler-data-form__infos --side-bg"></div>
                        <div
                           class="traveler-data-form__traveler-info"
                           v-for="(guestInfo, index) in $store.state.hotel.cookieData.guests"
                           :key="guestInfo + index">
                           <div class="sub-content">
                              <span class="label-title">{{ $t("transfer_travelerNameSurname") }}</span>
                              <span> {{ travelerInfo.name }} - {{ travelerInfo.surname }} </span>
                           </div>
                           <div class="sub-content">
                              <span class="label-title">{{ $t("transfer_travelerBirthDate") }}</span>
                              <span>
                                 {{ travelerInfo.birthdate | FormatterDate }}
                              </span>
                           </div>
                           <div class="sub-content">
                              <span class="label-title">{{ $t("transfer_travelerType") }}</span>
                              <span> {{ travelerInfo.type | ETrfTravelerType }} - {{ travelerInfo.gender | EComGender }} </span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="traveler-data-form__cta">
                     <UIButton
                        rounded
                        className="accent-primary_outline"
                        :text="$t('transfer_fillTravelerRowsCancelBtn')"
                        @click.native="showModal = false" />
                     <UIButton rounded className="accent-primary" :text="$t('transfer_fillTravelerRows')" @click.native="fillGuestRows" />
                  </div>
               </div>
            </div>
         </UIModal> -->
      </div>
      <template v-if="$store.state.hotel.isCheckoutPageLoaded">
         <div class="room-alert-wrapper" v-if="!partialPaymentActive">
            <Alert status="alert" v-if="lastBookingDate != null">
               <i class="gts-icon icon-triangular-info icon-lg"></i>
               <div class="room-alert-inner">
                  <p>
                     {{ $t("hotelCheckout_lblLastBookingDate") }}
                     <span v-if="!partialPaymentActive">{{ lastBookingDate | FormatterDate }} {{ lastBookingDate | TimeFormat }}</span>
                     <span v-else>{{ formattedFirstPaymentDate }}</span>
                  </p>
                  <p>{{ $t("hotelCheckout_lblLastBookingDateDescription") }}</p>
               </div>
            </Alert>
         </div>
         <div v-else>
            <GtsInfoWidgetWBorder
               v-if="partialPaymentActive"
               class="WBorder-Widget"
               icon="gts-icon icon-partial-payment-shadow"
               :title="$t('orderDetail_PartialPaymentDeadLineTitle')"
               :text="formattedPartialPaymentLastBookingDate"
               :ishtmlContent="true"
               :contentBody="replacePartialPaymentDeadLineBody">
            </GtsInfoWidgetWBorder>
            <GtsInfoWidgetWBorder
               v-if="partialPaymentActive"
               class="WBorder-Widget-2nd"
               icon="gts-icon icon-partial-payment-shadow"
               :title="$t('orderDetail_PartialCancellationPolicy')"
               :ishtmlContent="true"
               :contentBody="replaceCancellationPolicies">
            </GtsInfoWidgetWBorder>
         </div>
         <div class="checkout-section-inner room-info-cancellation-policies">
            <div v-if="!partialPaymentActive">
               <CancellationPolicies
                  className="hotel-checkout-cancellation-policies-wrapper"
                  :cancellationDate="cancellationPolicyDate"
                  :policies="cancellationPolicy" />
            </div>
            <div></div>
         </div>
      </template>
      <!-- <template v-if="$store.state.hotel.isCheckoutPageLoaded">
         <div v-if="partialPaymentEnabled">
            <CheckoutPartialPaymentDetails :partialPaymentModel="partialPaymentModel"></CheckoutPartialPaymentDetails>
         </div>
      </template> -->
      <div class="checkout-section-inner room-info-price row">
         <PriceWidget
            @commission-update="handlerAddCommission"
            @getAgencyCommisions="getAgencyCommisions"
            showCommission
            :countryCode="CountryCode"
            :totalPriceInfo="TotalPriceInfo"
            checkoutMode
            label="hotelCheckout" />
      </div>
   </section>
</template>

<script>
import { hotelService } from "@/service/index.js";
import moment from "moment";

import RoomInfoCard from "@/components/structural/hotel/checkout/RoomInfoCard.vue";
import RoomInfoCardSkeleton from "@/components/structural/hotel/checkout/RoomInfoCardSkeleton.vue";
import CancellationPolicies from "@/components/structural/hotel/common/CancellationPolicies.vue";
import PriceWidget from "@/components/unitary/PriceWidget.vue";
import Alert from "@/components/unitary/Alert.vue";
import GtsInfoWidgetWBorder from "@/components/utils/GtsInfoWidgetWBorder.vue";
// import UIModal from "@/components/unitary/form2/UIModal.vue";
// import CheckoutPartialPaymentDetails from "./CheckoutPartialPaymentDetails.vue";

export default {
   name: "RoomInfo",
   props: {
      partialPaymentCancellationPolicy: { type: Array },
      partialPaymentLastBookingDate: { type: String },
      countryCode: { type: String },
      contactModel: { type: Object },
      customerNationality: { type: String },
      roomsList: { type: Array },
      totalPriceInfo: { type: Object },
      providerPriceInfo: { type: Object },
      cancellationPolicies: { type: Array },
      cancellationPoliciesDate: { type: String },
      checkinDate: { type: String },
      checkoutDate: { type: String },
      lastBookingDate: { type: String },
      checkoutUuidProps: { type: String },
      searchUuidProps: { type: String },
      partialPaymentModel: { type: Array },
      partialPaymentDateUpdate: Boolean,
      partialPaymentActive: { type: Boolean, default: false },
   },
   components: {
      // CheckoutPartialPaymentDetails,
      RoomInfoCard,
      PriceWidget,
      CancellationPolicies,
      RoomInfoCardSkeleton,
      Alert,
      GtsInfoWidgetWBorder,
      // UIModal,
   },
   data() {
      return {
         agencyCommisionsModel: {},
         isFirstRender: true,
         roomsPaxes: [],
         rooms: [],
         providerInfo: {
            priceViewType: 1,
            baseRate: 0,
            tax: 0,
            providerTotal: 0,
            cityTax: 0,
         },
         checkoutRequestModel: {
            selectedAddOns: {},
            searchUuid: "",
            refreshCheckout: false,
            basketUuid: "",
            trxType: 1,
            agencyCommissionExtra: 0,
            agencyCommissionRatio: 0,
            rooms: [],
         },
      };
   },
   computed: {
      replacePartialPaymentDeadLineBody() {
         var firstDepositPriceText = this.$options.filters.price(
            this.partialPaymentCancellationPolicy[0].value,
            this.partialPaymentCancellationPolicy[0].currency
         );
         var firstDatePriceTextBold = "<span style='font-weight: 600;'>" + firstDepositPriceText + "</span>";
         return this.$t("orderDetail_PartialPaymentDeadLineBody").replace("#VALUE#", firstDatePriceTextBold);
      },
      replaceCancellationPolicies() {
         var firstLine = `<div style="margin-bottom: 8px;">${this.$t("orderDetail_PartialCancellationContentText1")}</div>`;

         var secondLine = `<li style="margin-bottom: 8px;">${this.$t("orderDetail_PartialCancellationContentText2").replace(
            "#VALUE#",
            `<span style='font-weight: 600;'> ${this.formattedPartialPaymentLastBookingDate}</span>`
         )}</li>`;

         // var thirdLine = `<li style="margin-bottom: 8px;">${this.$t("orderDetail_PartialCancellationContentText3")
         //    .replace("#VALUE1#", `<span style="font-weight:600"> ${this.formattedFirstPaymentDate}</span>`)
         //    .replace("$VALUE2#", `<span style="font-weight:600"> ${this.formattedFirstPaymentDateTo}</span>`)
         //    .replace(
         //       "#VALUE#",
         //       `<span style="font-weight:600"> ${this.$options.filters.price(
         //          this.partialPaymentCancellationPolicy[0].value,
         //          this.partialPaymentCancellationPolicy[0].currency
         //       )} </span>`
         //    )}</li>`;

         var fourthLine = `<li style="margin-bottom: 8px;">${this.$t("orderDetail_PartialCancellationContentText4")
            .replace(
               "#VALUE#",
               `<span style='font-weight: 600;'> ${this.$options.filters.price(
                  this.partialPaymentCancellationPolicy[1].value,
                  this.partialPaymentCancellationPolicy[1].currency
               )}</span>`
            )
            .replace("#VALUE1#", `<span style='font-weight: 600;'> ${this.formattedSecondPaymentDate} </span>`)
            .replace("#VALUE2", `<span style='font-weight: 600;'> ${this.formattedSecondPaymentDateTo} </span>`)}</li>`;

         var mainText = `<div style='display:flex; flex-direction: column;'>${firstLine} <ul style="list-style:disc; padding-left:1.25rem;">${secondLine}${fourthLine}</ul></div>`;

         return mainText;
      },
      formattedPartialPaymentLastBookingDate() {
         return moment(this.partialPaymentLastBookingDate).format("DD.MM.YYYY HH:mm");
      },
      formattedFirstPaymentDate() {
         return moment(this.partialPaymentCancellationPolicy[0].fromDate).format("DD.MM.YYYY HH:mm");
      },
      formattedFirstPaymentDateTo() {
         return moment(this.partialPaymentCancellationPolicy[0].toDate).format("DD.MM.YYYY HH:mm");
      },
      formattedSecondPaymentDate() {
         return moment(this.partialPaymentCancellationPolicy[1].fromDate).format("DD.MM.YYYY HH:mm");
      },
      formattedSecondPaymentDateTo() {
         return moment(this.partialPaymentCancellationPolicy[1].toDate).format("DD.MM.YYYY HH:mm");
      },
      CountryCode() {
         return this.countryCode;
      },
      roomList() {
         return this.roomsList;
      },
      TotalPriceInfo() {
         return { ...this.totalPriceInfo, ...this.ProviderPriceInfo };
      },
      cancellationPolicy() {
         return this.cancellationPolicies;
      },
      cancellationPolicyDate() {
         return this.cancellationPoliciesDate;
      },
      DefaultAgencyCommissionRatio() {
         return this.agencyCommission.options.indexOf(this.agencyCommission.options.find((option) => option.value == 0.1));
      },
      hotelName() {
         return this.$parent.$parent._data.hotelInformation.name;
      },
      ProviderPriceInfo() {
         return this.providerPriceInfo ? this.providerPriceInfo : {};
      },
   },
   watch: {
      roomList: {
         handler: function (newValue) {
            var self = this;
            self.rooms = [];
            newValue.map((roomItem) => {
               const roomObj = {
                  roomIdentifier: roomItem.roomIdentifier,
                  guests: roomItem.guests,
               };
               self.rooms.push(roomObj);
               this.$emit("update-to-rooms", self.rooms);
            });

            self.checkoutRequestModel.rooms = self.rooms;
         },
         deep: true,
      },
      checkoutRoomGuest: {
         handler: function (newValue) {
            var self = this;
            self.roomsPaxes = newValue;
         },
         deep: true,
      },
      checkoutUuidProps: function (value) {
         const self = this;
         self.checkoutRequestModel.checkoutUuid = value;
      },
      searchUuidProps: function (value) {
         const self = this;
         self.checkoutRequestModel.searchUuid = value;
      },
   },
   methods: {
      getAgencyCommisions(coms) {
         this.agencyCommisionsModel = coms;
      },
      handleUpdateAddons(addons) {
         this.$store.commit("app/setIsProgressGifActive", true);

         const self = this;
         self.checkoutRequestModel.selectedAddOns = addons;
         self.checkoutRequestModel.searchUuid = this.$route.params.uuid;
         self.checkoutRequestModel.basketUuid = this.$route.params.basketuuid;
         self.checkoutRequestModel.contact = this.contactModel;
         self.checkoutRequestModel.rooms = this.roomsList;
         self.checkoutRequestModel.agencyCommissionRatio = this.agencyCommisionsModel.agencyCommissionRatio;
         self.checkoutRequestModel.agencyCommissionExtra = this.agencyCommisionsModel.agencyCommissionInputValue;
         this.checkoutRequestModel.refreshCheckout = true;

         hotelService.hotelCheckout(self.checkoutRequestModel).then((response) => {
            this.checkinDate = response.checkinDate;
            this.checkoutDate = response.checkoutDate;
            var diffDate = moment(this.checkoutDate).diff(moment(this.checkinDate), "days");

            var checkoutBasketModel = {
               rooms: response.rooms,
               diffDate: diffDate,
               totalAmount: response.totalPrice,
               isCheckoutBasket: true,
               hotelInformation: {
                  name: response.hotelName,
                  address: response.hotelAddress,
                  star: response.hotelStar,
               },
            };
            this.$store.commit("hotel/updateBasketResponse", checkoutBasketModel);

            this.$parent.$parent.rooms = response.rooms;
            //this.roomsList = response.rooms;
            this.$parent.$parent.totalPrice = response.totalPrice;
            this.$parent.$parent.checkoutResponse = response;
            this.$store.commit("app/setIsProgressGifActive", false);
         });
      },
      handlerAddCommission(agencyCommissionInfo) {
         const self = this;
         const { type } = agencyCommissionInfo;
         const { agencyCommissionExtra, agencyCommissionRatio } = agencyCommissionInfo.agencyCommission;
         self.checkoutRequestModel.searchUuid = this.$route.params.uuid;
         self.checkoutRequestModel.basketUuid = this.$route.params.basketuuid;
         self.checkoutRequestModel.contact = this.contactModel;
         self.checkoutRequestModel.rooms = this.roomsList;

         // console.log(agencyCommissionInfo);
         if (type === "select" && this.isFirstRender) {
            this.isFirstRender = false;
            this.checkoutRequestModel.refreshCheckout = false;
         }
         switch (type) {
            case "select": {
               self.checkoutRequestModel.agencyCommissionRatio = agencyCommissionRatio;
               break;
            }

            case "input": {
               const agencyCommissionInputValue = Number(agencyCommissionExtra);
               self.checkoutRequestModel.agencyCommissionRatio = agencyCommissionRatio;
               self.checkoutRequestModel.agencyCommissionExtra = agencyCommissionInputValue;
               break;
            }
         }

         // for prevent users to enter negative value
         if (this.checkoutRequestModel.agencyCommissionExtra < 0) {
            this.checkoutRequestModel.agencyCommissionExtra = 0;
            return;
         }
         if (type === "select" && this.isFirstRender) {
            this.isFirstRender = false;
            this.checkoutRequestModel.refreshCheckout = false;
            this.$store.commit("app/setIsProgressGifActive", false);
         } else {
            this.checkoutRequestModel.refreshCheckout = true;
            this.$store.commit("app/setIsProgressGifActive", true);
         }
         this.$store.state.app.progress.message = this.$i18n.t("hotelCheckout_agencyExtraCCommission");
         hotelService.hotelCheckout(self.checkoutRequestModel).then((response) => {
            this.checkinDate = response.checkinDate;
            this.checkoutDate = response.checkoutDate;
            var diffDate = moment(this.checkoutDate).diff(moment(this.checkinDate), "days");

            var checkoutBasketModel = {
               rooms: response.rooms,
               diffDate: diffDate,
               totalAmount: response.totalPrice,
               isCheckoutBasket: true,
               hotelInformation: {
                  name: response.hotelName,
                  address: response.hotelAddress,
                  star: response.hotelStar,
               },
            };
            this.$store.commit("hotel/updateBasketResponse", checkoutBasketModel);

            this.$parent.$parent.rooms = response.rooms;
            //this.roomsList = response.rooms;
            this.$parent.$parent.totalPrice = response.totalPrice;
            this.$parent.$parent.checkoutResponse = response;
            this.$store.commit("app/setIsProgressGifActive", false);
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.room-info-section {
   .checkout-section-inner {
      .room-info-item {
         margin-bottom: 32px;
         padding-bottom: 32px;
         background-image: linear-gradient(to right, #000 50%, rgba(255, 255, 255, 0) 50%);
         background-position: bottom;
         background-size: 10px 2px;
         background-repeat: repeat-x;

         &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            background-image: none;
         }
      }
      &.room-info-card {
         margin-bottom: 40px;
         .checkout-section-title {
            &.masked {
               width: 60%;
            }
         }
         @media (max-width: 767.98px) {
            .checkout-section-title {
               &.masked {
                  width: auto;
               }
            }
         }
      }
      &.room-info-price {
         margin-top: 2rem;
      }
   }
   .room-alert-wrapper {
      margin-bottom: 8px;
      ::v-deep i {
         // width: 40px !important;
         margin-right: 8px;
         filter: invert(79%) sepia(93%) saturate(1003%) hue-rotate(338deg) brightness(100%) contrast(81%);
      }
      ::v-deep .room-alert-inner {
         p {
            color: #363940;
            font-size: 14px;
            font-weight: 700;

            & > span {
               margin-right: 8px;
               font-size: 20px;
               font-weight: 900;
            }
         }
      }
   }
}
</style>
