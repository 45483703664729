<template>
   <div id="search-information-popup-wrapper" v-if="status">
      <div class="search-information-popup-container">
         <div class="lottie-container"><lottie-animation :speed="0.75" path="assets/animations/searchPopup.json" /></div>
         <p class="popup-text">{{ $t(`${label}_text`) }}</p>
         <div class="search-information-popup-container__information">
            <div class="info-row">
               <div class="icon"><i class="gts-icons icon-location-search-popup"></i></div>
               <div v-if="variant == 'hotel'" class="data">
                  <p class="title">{{ $t(`${label}_location`) }}</p>
                  <p class="subtitle">{{ informationpopup?.destination.name }}</p>
               </div>
               <!-- Transfer Location Infos -->
               <div v-if="variant == 'transfer'" class="info-row__route">
                  <div class="date">
                     <p class="title">{{ $t(`${label}_locationFrom`) }}</p>
                     <p class="subtitle">{{ informationpopup.from.destinationName.split(",")[0] }}</p>
                  </div>
                  <div class="date">
                     <p class="title">{{ $t(`${label}_locationTo`) }}</p>
                     <p class="subtitle">{{ informationpopup.to.destinationName.split(",")[0] }}</p>
                  </div>
               </div>
               <!-- ------------------------------ -->
               <!-- Rentacar Location Infos -->
               <div v-if="variant == 'rentacar'" class="info-row__route">
                  <div class="date">
                     <p class="title">{{ $t(`${label}_locationFrom`) }}</p>
                     <p class="subtitle">{{ informationpopup.pickupFrom }}</p>
                  </div>
                  <div class="date" v-if="informationpopup?.dropOff">
                     <p class="title">{{ $t(`${label}_locationTo`) }}</p>
                     <p class="subtitle">{{ informationpopup?.dropOff }}</p>
                  </div>
               </div>
               <!-- ------------------------------ -->
            </div>
            <div class="info-row" v-if="variant != 'rentacar'">
               <div class="icon"><i class="gts-icons icon-family"></i></div>
               <div class="data">
                  <p class="title">{{ $t(`${label}_data`) }}</p>
                  <p class="subtitle">{{ setInfoText() }}</p>
               </div>
            </div>
            <div class="info-row">
               <div class="icon"><i class="gts-icons icon-calendar-search-popup"></i></div>
               <div class="info-row__date">
                  <div class="date">
                     <p class="title">{{ $t($t(`${label}_date1`)) }}</p>
                     <p class="subtitle" v-if="variant == 'rentacar'">
                        {{
                           informationpopup.checkInDate ? informationpopup.checkInDate : informationpopup.pickupDateTime | FormatterDD_MM_YYYY_HH_mm
                        }}
                     </p>
                     <p class="subtitle" v-else>
                        {{ informationpopup.checkInDate ? informationpopup.checkInDate : informationpopup.pickupDateTime | FormatterDate }}
                     </p>
                  </div>
                  <div class="date" v-if="variant == 'hotel'">
                     <p class="title">{{ $t($t(`${label}_date2`)) }}</p>
                     <p class="subtitle">{{ informationpopup.checkOutDate | FormatterDate }}</p>
                  </div>
                  <div class="date --ml" v-if="variant == 'transfer' && informationpopup.returnDate">
                     <p class="title">{{ $t($t(`${label}_date2`)) }}</p>
                     <p class="subtitle">{{ informationpopup.returnDate | FormatterDate }}</p>
                  </div>
                  <div class="date" v-if="variant == 'rentacar'">
                     <p class="title">{{ $t($t(`${label}_date2`)) }}</p>
                     <p class="subtitle">{{ informationpopup.dropOffDateTime | FormatterDD_MM_YYYY_HH_mm }}</p>
                  </div>
               </div>
            </div>
         </div>
         <div v-if="hasFooter" class="search-information-popup-container__footer">
            <i class="gts-icon icon-gts-info-icon-black icon-sm"></i>{{ setFooterText() }}
         </div>
      </div>
   </div>
</template>
<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import moment from "moment";
export default {
   name: "SearchInformationPopup",
   components: {
      LottieAnimation,
   },
   data() {
      return {
         informationpopup: null,
         status: false,
      };
   },
   props: {
      label: {
         type: String,
         default: "search_information_popup",
      },
      variant: {
         type: String,
         default: "hotel",
      },
      hasFooter: {
         type: Boolean,
         default: false,
      },
   },
   methods: {
      setInfoText() {
         const label = this.label;

         let roomCount = null;
         let adultCount = null;
         let childCount = null;
         let infantCount = null;
         switch (this.variant) {
            case "hotel":
               roomCount = this.informationpopup.rooms.length;
               this.informationpopup.rooms.forEach((room) => {
                  adultCount += room.adults;
                  childCount += room.children;
               });
               return `${roomCount} ${this.$t(`${label}_rooms`)}, ${adultCount} ${this.$t(`${label}_adult`)} ${
                  childCount ? this.$t(`${label}_child`).replace("#COUNT#", childCount) : ""
               }`;
            case "transfer":
               adultCount = this.informationpopup.travellers.adult;
               childCount = this.informationpopup.travellers.child;
               infantCount = this.informationpopup.travellers.infant;

               return `${this.informationpopup.travellers.adult} ${this.$t(`${label}_adult`)}${
                  childCount ? this.$t(`${label}_child`).replace("#COUNT#", childCount) : ""
               } 
               ${infantCount ? this.$t(`${label}_infant`).replace("#COUNT#", infantCount.toString()) : ""}`;
            default:
               return "";
         }
      },
      setFooterText() {
         const label = this.label;
         let nightCount;
         let dayCount;

         switch (this.variant) {
            case "hotel":
               dayCount = moment(this.informationpopup.checkOutDate).diff(moment(this.informationpopup.checkInDate), "days") + 1;
               nightCount = dayCount - 1;
               return `${this.$t(`${label}_footer`).replace("#DAY#", dayCount).replace("#NIGHT#", nightCount)}`;
         }
      },
   },
   watch: {
      variant: {
         handler(newVariant) {
            if (newVariant === "hotel") {
               this.$watch("$store.state.hotel.isSearching", function (value) {
                  this.status = value;
               });
               this.$watch("$store.state.hotel.searchQuery", function (value) {
                  this.informationpopup = value;
               });
            } else if (newVariant === "transfer") {
               this.$watch("$store.state.transfer.isSearching", function (value) {
                  this.status = value;
               });
               this.$watch("$store.state.transfer.searchQuery", function (value) {
                  this.informationpopup = value;
               });
            } else if (newVariant === "rentacar") {
               this.$watch("$store.state.rentacar.isSearching", function (value) {
                  this.status = value;
               });
               this.$watch("$store.state.rentacar.searchQuery", function (value) {
                  this.informationpopup = value;
               });
            }
         },
         immediate: true, // Trigger the handler immediately when the component is created
      },
   },
};
</script>

<style lang="scss" scoped>
#search-information-popup-wrapper {
   position: fixed;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   background-color: #00000050;
   z-index: 9999;

   .search-information-popup-container {
      width: fit-content;
      padding: 2rem 1rem 0 1rem;
      height: fit-content;
      background: #fff;
      box-shadow: 2px 2px 6px #5c75991a;
      border: 1px solid #e6e6e6;
      border-radius: 16px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90vw;
      @media (min-width: 576px) {
         width: 500px;
         padding: 2rem 0 0 0;
      }
      .popup-text {
         //medium font weight
         font-weight: 500;
         font-size: 1.125rem;
         margin-top: 1rem;
         text-align: center;
         color: #525c66;
         padding: 1.115rem;
      }
      &__information {
         border-top: 2px solid #e6e6e6;
         padding: 2rem 5rem 3rem 5rem;
         width: 100%;
         .info-row {
            &:not(:first-child) {
               margin-top: 1.5rem;
            }
            display: flex;
            .subtitle {
               color: #363636;
               font-size: 1rem;
            }
            .title {
               font-size: 1rem;
               font-weight: bold;
            }
            .icon {
               width: 42px;
               height: 42px;
               i {
                  background-repeat: no-repeat;
                  height: 34px;
                  width: 42px;
               }
            }
            &__route {
               .subtitle {
                  max-width: 100px;
                  width: 100px;
               }
               .date {
                  &:nth-child(2) {
                     margin-left: 1.25rem;
                  }
               }
            }
            &__date,
            &__route {
               display: flex;
               width: 80%;
               .date {
                  margin-left: 1rem;
               }
            }
            .data {
               margin-left: 1rem;
            }
         }
      }
      &__footer {
         background: #dce0e6 0% 0% no-repeat padding-box;
         box-shadow: 2px 2px 6px #5c75991a;
         border-radius: 0px 0px 16px 16px;
         width: 90vw;
         padding: 0.5rem;
         display: flex;
         justify-content: center;
         font-size: 0.875rem;
         i {
            margin-right: 0.75rem;
         }
         @media (min-width: 576px) {
            width: 500px;
         }
      }
   }
}
</style>
