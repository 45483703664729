<template>
   <div class="flight-sales-repor-container sub-page-wrapper report-page-container">
      <Aside />
      <Main>
         <Navbar />

         <div class="container-fluid">
            <!-- {{ tableOptions }} -->
            <!-- Sale Report Forms -->
            <div class="row">
               <div class="col gts-box mx-3">
                  <h1 class="sub-page-header">{{ $t("flightSalesReport_title") }}</h1>

                  <!-- SALES PAGES INPUTS -->
                  <div class="sales-page-input-wrapper row gy-3">
                     <!-- REPORT TYPE SELECTION -->
                     <div class="col-12 col-md-6 col-lg-2 order-1 order-lg-1 reportType">
                        <UISelectBox
                           :key="refreshKey3"
                           :label="$t('flightSalesReport_reportType')"
                           id="report-type"
                           :options="reportType"
                           v-model="searchQuery.dateType"
                           light-theme />
                     </div>

                     <!-- CREATION FROM DATE -->
                     <div class="col-12 col-md-6 col-lg-2 order-3 order-lg-2 fromDate">
                        <UIDatePicker
                           :key="refreshKey1"
                           :label="$t('flightSalesReport_creationFromDate')"
                           v-model="searchQuery.date1.value"
                           :min="searchQuery.date1.min"
                           :max="searchQuery.date1.max" />
                     </div>

                     <!-- CREATION To DATE -->
                     <div class="col-12 col-md-6 col-lg-2 order-4 order-lg-3 toDate">
                        <UIDatePicker
                           :key="refreshKey2"
                           :label="$t('flightSalesReport_creationToDate')"
                           v-model="searchQuery.date2.value"
                           :min="searchQuery.date2.min"
                           :max="searchQuery.date2.max" />
                     </div>

                     <!-- PNR STATUS -->
                     <div class="col-12 col-md-6 col-lg-2 order-2 order-lg-4 pnrStatus">
                        <UIMultiSelect
                           :key="fltRefreshKey"
                           :label="$t('flightSalesReport_pnrStatus')"
                           id="pnr-status"
                           :options="pnrStatusList"
                           v-model="searchQuery.pnrStatuses"
                           light-theme
                           allow-search />
                     </div>

                     <!-- AIRLINE LIST -->
                     <div class="col-12 col-md-6 col-lg-2 order-5 order-lg-5 airlineList">
                        <UIMultiSelect
                           :label="$t('flightSalesReport_airlineList')"
                           id="airline-list"
                           :options="airlineList"
                           v-model="searchQuery.airlineIds"
                           light-theme
                           allSelected
                           allow-search />
                     </div>

                     <!-- PROVIDER LIST -->
                     <div class="col-12 col-md-6 col-lg-2 order-5 order-lg-5 providerList">
                        <UIMultiSelect
                           :label="$t('flightSalesReport_providerList')"
                           id="provider-list"
                           :options="providersList"
                           v-model="searchQuery.providerIds"
                           light-theme
                           allSelected
                           allow-search />
                     </div>

                     <!-- USER LIST -->
                     <div class="col-12 col-md-6 col-lg-2 order-5 order-lg-6 userList">
                        <UIMultiSelect
                           :label="$t('flightSalesReport_userList')"
                           id="user-list"
                           :options="userList"
                           :mergeAllAsOne="false"
                           v-model="searchQuery.userIds"
                           light-theme
                           allSelected
                           allow-search />
                     </div>
                  </div>

                  <!-- SALES PAGES CTA -->
                  <div class="sub-page-actions-row row">
                     <div class="sub-pages-actions-wrapper col-12">
                        <div class="toggle-container">
                           <div class="toggle-wrapper">
                              <label>{{ $t("flightSalesReport_showReissues") }}</label>
                              <b-switch v-model="searchQuery.showReissues"> </b-switch>
                           </div>
                        </div>

                        <UIButton className="default" :text="$t('common_search')" @click.native="handlerListSalesReport" />
                     </div>
                  </div>
               </div>
            </div>

            <!-- Summary & Sale Report -->
            <template v-if="reportLoad">
               <!-- Sale Report Summary -->
               <ReportSummary :entityType="'flight'" :summary="summary" />

               <!-- Sale Result Container -->
               <div class="smart-table-wrapper">
                  <template v-if="tableMode == 'alternative'">
                     <SmartTable :options="alternativeTable" @table-mode="handleMode" />
                  </template>
                  <template v-if="tableMode == 'default'">
                     <SmartTable :options="table" @table-mode="handleMode" />
                  </template>
               </div>
            </template>
         </div>
      </Main>
   </div>
</template>

<script>
import { reportService } from "@/service/index.js";
import { agencyService } from "@/service/index.js";
import { userService } from "@/service/index.js";
import { globalService } from "@/service/index.js";

import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";
import i18n from "@/fmt/i18n.js";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIMultiSelect from "@/components/unitary/form2/UIMultiSelect.vue";
import UIDatePicker from "@/components/unitary/form2/UIDatePicker2.vue";
import ReportSummary from "@/components/unitary/SalesReportSummary.vue";

import UIButton from "@/components/unitary/Button.vue";

import moment from "moment";

import Popup from "@/gts-popup.js";

export default {
   name: "FlightSalesReport",
   components: {
      Aside,
      Navbar,
      Main,
      UISelectBox,
      UIMultiSelect,
      UIDatePicker,
      UIButton,
      ReportSummary,
   },
   data() {
      return {
         isFirstSearch: true,
         refreshKey1: 1,
         refreshKey2: 5,
         refreshKey3: 7,
         fltRefreshKey: 0,
         startDateString: moment().format(this.$constants.dateFormat),
         endDateString: moment().format(this.$constants.dateFormat),
         dateDiff: 0,
         searchQuery: {
            dateType: 1,
            date1: {
               min: moment("2022-01-01").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            date2: {
               min: moment("2022-01-01").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            pnrStatuses: [2, 3],
            providerIds: [],
            userIds: [],
            airlineIds: [],
            inpSwithPnrEticket: false,
            showReissues: true,
         },
         reportType: [
            { name: `${i18n.t("flightSalesReport_transactionDate")}`, value: 1 },
            { name: `${i18n.t("flightSalesReport_flightDate")}`, value: 2 },
         ],
         reportMode: [
            { name: `${i18n.t("common_eticket")}`, value: 1 },
            { name: `${i18n.t("common_pnrs")}`, value: 2 },
         ],
         reportLoad: false,
         pnrStatusList: [],
         providersList: [],
         userList: [],
         airlineList: [],

         table: {
            columns: [
               { label: `${i18n.t("common_orderId")}`, status: true, identifier: "orderId" },
               { label: `${i18n.t("common_pnrs")}`, status: true },
               { label: `${i18n.t("common_pnrStatus")}`, status: true },
               { label: `${i18n.t("common_airlines")}`, status: true },
               { label: `${i18n.t("common_providerName")}`, status: true },
               { label: `${i18n.t("common_paxCount")}`, status: true },
               { label: `${i18n.t("common_nameSurname")}`, status: true },
               { label: `${i18n.t("common_salesTotal")}`, status: true },
               { label: `${i18n.t("common_totalRefund")}`, status: true },
               { label: `${i18n.t("common_currency")}`, status: true },
               { label: `${i18n.t("common_saleTime")}`, status: true },
               { label: `${i18n.t("common_cancellationTime")}`, status: true },
               { label: `${i18n.t("common_departureTime")}`, status: true },
               { label: `${i18n.t("common_optionExpiryTime")}`, status: true },
               { label: `${i18n.t("common_userEmail")}`, status: true },
            ],
            rows: [],
            rules: {
               autoMerge: ["orderId", "pnr", "pnrStatus"],
               dominantCell: "orderId", //dominantCell should be an object with text property
               showOnMobile: [0, 1, 2, 6, 7],
               highlightedColumns: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
            },
            options: {
               header: {
                  title: this.$options.name,
                  viewOptions: {
                     status: true,
                     perPage: 50,
                     selects: [
                        { name: "3 Order", value: 3 },
                        { name: "5 Order", value: 5 },
                        { name: "25 Order", value: 25 },
                        { name: "50 Order", value: 50 },
                        { name: "100 Order", value: 100 },
                        { name: "Show All", value: null },
                     ],
                  },
                  viewMode: "default",
                  saleDateIndex: 10,
                  viewModes: [
                     { name: "E-Ticket", value: "alternative" },
                     { name: "Pnr", value: "default" },
                  ],
               },
               footer: {
                  pagination: {
                     pages: [],
                     activepage: 1,
                  },
               },
            },
         },
         alternativeTable: {
            columns: [
               { label: `${i18n.t("common_orderId")}`, status: true, identifier: "orderId" },
               { label: `${i18n.t("common_pnrs")}`, status: true },
               { label: `${i18n.t("common_pnrStatus")}`, status: true },
               { label: `${i18n.t("common_airlines")}`, status: true },
               { label: `${i18n.t("common_providerName")}`, status: true },
               { label: `${i18n.t("common_eticket")}`, status: true },
               { label: `${i18n.t("common_eticketStatus")}`, status: true },
               { label: `${i18n.t("common_nameSurname")}`, status: true },
               { label: `${i18n.t("common_salesTotal")}`, status: true },
               { label: `${i18n.t("common_totalRefund")}`, status: true },
               { label: `${i18n.t("common_currency")}`, status: true },
               { label: `${i18n.t("common_saleTime")}`, status: true },
               { label: `${i18n.t("common_cancellationTime")}`, status: true },
               { label: `${i18n.t("common_departureTime")}`, status: true },
               { label: `${i18n.t("common_optionExpiryTime")}`, status: true },
               { label: `${i18n.t("common_userEmail")}`, status: true },
            ],
            rows: [],
            rules: {
               autoMerge: ["orderId", "pnr", "pnrStatus"],
               dominantCell: "orderId", //dominantCell should be an object with text property
               showOnMobile: [0, 1, 2, 5, 6, 7],
               highlightedColumns: [7, 8, 9, 10, 11, 12, 13, 14, 15],
            },
            options: {
               header: {
                  title: "Flight Sale Report",
                  viewOptions: {
                     status: true,
                     perPage: 50,
                     selects: [
                        { name: "3 Order", value: 3 },
                        { name: "5 Order", value: 5 },
                        { name: "25 Order", value: 25 },
                        { name: "50 Order", value: 50 },
                        { name: "100 Order", value: 100 },
                        { name: "Show All", value: null },
                     ],
                  },
                  viewMode: "alternative",
                  saleDateIndex: 11,
                  viewModes: [
                     { name: "E-Ticket", value: "alternative" },
                     { name: "Pnr", value: "default" },
                  ],
               },
            },
         },
         tableMode: "default",
         summary: null,
      };
   },

   mounted() {
      const self = this;
      //Provider List
      agencyService.listProviders({ type: 1 }).then((res) => {
         res.providers.map((provider) => {
            const rowObj = {
               value: provider.id,
               name: provider.name,
            };
            self.providersList.push(rowObj);
            self.searchQuery.providerIds.push(provider.id);
         });
      });

      //Airline List
      globalService.getAirlineList().then((response) => {
         if (response.length != 0) {
            response.map((airline) => {
               const rowObj = {
                  value: airline.iataCode,
                  name: airline.iataLabel + " - " + airline.nameTr,
               };
               self.airlineList.push(rowObj);
               self.searchQuery.airlineIds.push(airline.iataCode);
            });
         }
      });

      // pnrStatuses List
      this.getStatusList();
      //User List
      userService.listUsers({}).then((res) => {
         res.users.map((user) => {
            const rowObj = {
               value: user.id,
               name: `${user.name}<br/><span class="txt-small">${user.email}</span>`,
            };
            self.userList.push(rowObj);
            self.searchQuery.userIds.push(user.id);
         });
      });
   },
   methods: {
      getStatusList() {
         this.pnrStatusList = [];
         for (let i = 1; i < 15; i++) {
            const rowObj = { value: i, name: this.$options.filters.EFltPnrStatus(i) };
            this.pnrStatusList.push(rowObj);
         }
         this.fltRefreshKey += 1;
         this.$nextTick(() => {
            this.pnrStatusList = this.pnrStatusList.slice();
         });
      },
      getTableColumns() {
         return [
            { label: `${i18n.t("common_orderId")}`, status: true, identifier: "orderId" },
            { label: `${i18n.t("common_pnrs")}`, status: true },
            { label: `${i18n.t("common_pnrStatus")}`, status: true },
            { label: `${i18n.t("common_airlines")}`, status: true },
            { label: `${i18n.t("common_providerName")}`, status: true },
            { label: `${i18n.t("common_paxCount")}`, status: true },
            { label: `${i18n.t("common_nameSurname")}`, status: true },
            { label: `${i18n.t("common_salesTotal")}`, status: true },
            { label: `${i18n.t("common_totalRefund")}`, status: true },
            { label: `${i18n.t("common_currency")}`, status: true },
            { label: `${i18n.t("common_saleTime")}`, status: true },
            { label: `${i18n.t("common_cancellationTime")}`, status: true },
            { label: `${i18n.t("common_departureTime")}`, status: true },
            { label: `${i18n.t("common_optionExpiryTime")}`, status: true },
            { label: `${i18n.t("common_userEmail")}`, status: true },
         ];
      },
      getTableColumnsAlternative() {
         return [
            { label: `${this.$i18n.t("taskList_tblMain_thId")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thOrderId")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thType")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thTaskStatus")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thOrderStatus")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thEntityType")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thEntity")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thNote")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thGsa")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thAgency")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thCreatedUser")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thClosingType")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thUpdatedDateTime")}` },
         ];
      },
      updateTableColumns() {
         this.table.columns = this.getTableColumns();
         this.alternativeTable.columns = this.getTableColumnsAlternative();
      },
      handleMode(mode) {
         this.tableMode = mode;
      },
      handlerListSalesReport() {
         const self = this;
         self.reportLoad = false;
         self.table.rows = [];
         self.alternativeTable.rows = [];

         this.calculateDateDiff();

         if (this.dateDiff > 365) {
            Popup.info(null, this.$i18n.t("Popup_lblFlightSalesReportDateRange"));
         } else {
            self.$store.commit("app/setIsProgressGifActive", true);

            let request = {
               date1: self.searchQuery.date1.value,
               date2: self.searchQuery.date2.value,
               dateType: self.searchQuery.dateType,
               inpSwithPnrEticket: self.searchQuery.inpSwithPnrEticket,
               pnrStatuses: self.searchQuery.pnrStatuses,
               providerIds: self.searchQuery.providerIds,
               marketingAirlineCodes: self.searchQuery.airlineIds,
               showReissues: self.searchQuery.showReissues,
               userIds: self.searchQuery.userIds,
            };
            // Change rule set when status is Cancelled , Issued , Refunded change row spans - Deniz 08.02.2023
            // ------------------------------------------
            const includeCancel = request.pnrStatuses.some((status) => {
               return status == 4 || status == 6 || status == 3;
            });

            if (includeCancel) {
               this.table.rules.autoMerge = ["orderId"];
               this.alternativeTable.rules.autoMerge = ["orderId"];
            }
            // ------------------------------------------

            reportService.flightSalesReport(request).then((res) => {
               if (res.result.success) {
                  self.reportLoad = false;
                  this.$store.commit("app/setIsProgressGifActive", false);
                  self.summary = res.summary;
                  res.items.map((item) => {
                     const rowObj = {
                        orderId: {
                           text: item.orderId,
                           class: `gts-badge smart-table-badge-1`,
                           url: `/order/detail/${item.orderUuid}`,
                        },
                        pnr: {
                           text: item.pnr,
                           class: `pnr-class`,
                        },
                        pnrStatus: {
                           pnrStatus: item.pnrStatus,
                           text: this.$options.filters.EFltPnrStatus(item.pnrStatus),
                           class: `pnr-badge gts-badge EFltPnrStatus-${item.pnrStatus}`,
                        },
                        airlines: {
                           text: item.marketingAirlineCodes,
                           class: `marketing-airline`,
                        },
                        provider: item.provider,
                        paxCount: item.paxCount,
                        passenger: item.passenger,

                        salesTotal: {
                           text: this.$options.filters.price(item.eticketSaleAmount),
                           class: "price-display",
                        },
                        refundTotal: {
                           text: this.$options.filters.price(item.eticketReverseAmount),
                           class: "price-display",
                        },
                        currency: item.currency,
                        saleDateTime: {
                           text: this.$options.filters.fullDateFormat(item.saleDateTime),
                           class: "",
                        },
                        reverseDateTime: {
                           text: this.$options.filters.fullDateFormat(item.reverseDateTime),
                           class: "",
                        },
                        departureDateTime: {
                           text:
                              this.$options.filters.fullDateFormat(item.departureDateTime) +
                              " " +
                              this.$options.filters.TimeFormat(item.departureDateTime),
                           class: "",
                        },
                        optionDateTime: {
                           text:
                              this.$options.filters.fullDateFormat(item.optionDateTime) + " " + this.$options.filters.TimeFormat(item.optionDateTime),
                           class: "",
                        },
                        saleUserEmail: item.saleUserEmail,
                     };
                     const alternative = {
                        orderId: {
                           text: item.orderId,
                           class: `gts-badge smart-table-badge-1`,
                           url: `/order/detail/${item.orderUuid}`,
                        },
                        pnr: item.pnr,
                        pnrStatus: {
                           pnrStatus: item.pnrStatus,
                           text: this.$options.filters.EFltPnrStatus(item.pnrStatus),
                           class: `pnr-badge gts-badge EFltPnrStatus-${item.pnrStatus}`,
                        },
                        airlines: {
                           text: item.marketingAirlineCodes,
                           class: `marketing-airline`,
                        },
                        provider: item.provider,
                        eticket: {
                           text: `${item.eticket}`,
                           class: "",
                        },
                        eticketStatus: {
                           text: this.$options.filters.EFltPnrStatus(item.eticketStatus),
                           class: `pnr-badge gts-badge EFltPnrStatus-${item.pnrStatus}`,
                        },
                        passenger: item.passenger,
                        salesTotal: {
                           text: this.$options.filters.price(item.eticketSaleAmount),
                           class: "price-display",
                        },
                        refundTotal: {
                           text: this.$options.filters.price(item.eticketReverseAmount),
                           class: "price-display",
                        },
                        currency: item.currency,
                        saleDateTime: {
                           text: this.$options.filters.fullDateFormat(item.saleDateTime),
                           class: "",
                        },
                        reverseDateTime: {
                           text: this.$options.filters.fullDateFormat(item.reverseDateTime),
                           class: "",
                        },
                        departureDateTime: {
                           text:
                              this.$options.filters.fullDateFormat(item.departureDateTime) +
                              " " +
                              this.$options.filters.TimeFormat(item.departureDateTime),
                           class: "",
                        },
                        optionDateTime: {
                           text:
                              this.$options.filters.fullDateFormat(item.optionDateTime) + " " + this.$options.filters.TimeFormat(item.optionDateTime),
                           class: "",
                        },
                        saleUserEmail: item.saleUserEmail,
                     };

                     this.table.rows.push(rowObj);

                     this.alternativeTable.rows.push(alternative);
                  });
                  self.handleMode("default");

                  self.reportLoad = true;
                  this.isFirstSearch = false;
               }
            });
         }
      },
      calculateDateDiff() {
         let start = moment(this.startDateString);
         let end = moment(this.endDateString);
         let duration = moment.duration(end.diff(start));
         let days = duration.asDays();
         this.dateDiff = Math.round(days);
      },
      funcRefreshKeys() {
         this.refreshKey1++;
         this.refreshKey2++;
         this.refreshKey3++;
      },
   },
   watch: {
      "searchQuery.dateType"() {
         if (this.searchQuery.dateType == 1) {
            this.searchQuery.date1.max = moment().format(this.$constants.dateFormat);
            this.searchQuery.date2.max = moment().format(this.$constants.dateFormat);
         } else if (this.searchQuery.dateType == 2) {
            this.searchQuery.date1.max = moment().add(1, "year").format(this.$constants.dateFormat);
            this.searchQuery.date2.max = moment().add(1, "year").format(this.$constants.dateFormat);
         }
         this.funcRefreshKeys();
      },
      "searchQuery.date1.value"() {
         this.startDateString = moment(this.searchQuery.date1.value).format(this.$constants.dateFormat);
         // this.searchQuery.date2.max = moment(this.searchQuery.date1.value).add(1, "year").format(this.$constants.dateFormat);
      },
      "searchQuery.date2.value"() {
         this.endDateString = moment(this.searchQuery.date2.value).format(this.$constants.dateFormat);
         // this.searchQuery.date1.min = moment(this.searchQuery.date2.value).subtract(1, "year").format(this.$constants.dateFormat);
      },
      "$i18n.locale"() {
         this.getStatusList();
         if (this.isFirstSearch == false) {
            this.handlerListSalesReport();
         }
         this.updateTableColumns();
         this.funcRefreshKeys();
         this.reportType = [
            { name: `${i18n.t("flightSalesReport_transactionDate")}`, value: 1 },
            { name: `${i18n.t("flightSalesReport_flightDate")}`, value: 2 },
         ];
      },
   },
};
</script>

<style lang="scss">
.sub-page-wrapper {
   .container-fluid {
      @media (min-width: 1200px) {
         width: 90%;
      }

      @media (min-width: 992px) {
         .reportType {
            width: 13%;
         }

         .fromDate {
            width: 10%;
         }

         .toDate {
            width: 10%;
         }
      }

      .sub-page-header {
         font-size: var(--xxlarge);
         font-weight: 600;
         margin-bottom: 2rem;
         @media (max-width: 768px) {
            margin-top: 1rem;
         }
      }

      .sub-page-row-wrapper {
         margin-bottom: 1rem;
      }

      .sub-page-actions-row {
         display: flex;
         justify-content: flex-end;
         margin-top: 1.5rem;
         .sub-pages-actions-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .toggle-container {
               margin-right: 1rem;
               .toggle-wrapper {
                  display: flex;
                  label {
                     margin-right: 0.5rem;
                     font-weight: 500;
                  }
               }
            }
         }
      }
   }
   .smart-table-wrapper {
      margin-top: 24px;
   }
}
</style>
