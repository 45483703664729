<template>
   <div id="app-wrapper" ref="appwrapper">
      <!-- <Transition name="fade"> -->
      <router-view v-if="loadPage" />
      <!-- </Transition> -->

      <!-- DOCUMENT VIEWER -->

      <DocumentViewer v-if="documentViewer" />

      <!-- <template v-if="statusError.htmlContent == ''">
            <UIPopup :status="statusError.type" :title="statusError.title" :subTitle="statusError.subTitle" v-if="statusError.isPopupActive" />
        </template>
        <template v-else>
              </template> -->

      <UIPopup
         :status="statusError.type"
         :htmlContent="statusError.htmlContent"
         :title="statusError.title"
         :subTitle="statusError.subTitle"
         :extra="statusError.extra"
         :customCancel="statusError.customCancel"
         :confirmButtonCb="statusError.confirmButtonCb"
         v-if="statusError.isPopupActive" />

      <template>
         <UINotification />
      </template>
      <Transition name="fade" mode="out-in">
         <UIProgressGif v-show="this.$store.state.app.progress.status" />
      </Transition>

      <button id="gotoTop" v-if="topButton" @click="handlerGoToTop">
         <i class="gts-icon icon-arrow-up"></i>
      </button>
      <!-- <UIProgressGif v-if="true" /> -->
   </div>
</template>

<script>
import Vue from "vue";
import "@/assets/css/flag-icon.min.css";

import { globalService, TokenService } from "@/service/index.js";
import { useFavicon, useTitle } from "@vueuse/core";
const icon = useFavicon();
const title = useTitle();

// import '@/assets/css/multiselect.css';
import DocumentViewer from "@/components/unitary/DocumentViewer.vue";
import UIPopup from "@/components/utils/Popup.vue";
import UINotification from "@/components/utils/Notification.vue";
// import { setCookie, getCookie } from "@/utils.js";
import UIProgressGif from "@/components/unitary/ProgressGif.vue";
import { errorManager } from "./helper/errormanager";

export default {
   name: "App",
   components: {
      UIPopup,
      UIProgressGif,
      UINotification,
      DocumentViewer,
   },
   data() {
      return {
         loadPage: false,
         statusError: {
            isPopupActive: false,
            type: "",
            title: "",
            subTitle: "",
            htmlContent: "",
            bind: "",
            customCancel: "",
            extra: {},
         },
         topButton: false,
      };
   },
   created() {
      console.log(process.env.VUE_APP_ENV + " in developer mode console.log function is active");
      this.fetchCountries();

      this.handlerStartpService();
   },
   mounted() {
      if (process.env.VUE_APP_ENV !== "PROD") {
         window.addEventListener("keydown", this.handleKeyDown);
      }
      const self = this;
      window.addEventListener("scroll", function () {
         if (document.documentElement.scrollTop > 50) {
            self.topButton = true;
         } else {
            self.topButton = false;
         }
      });

      //Control for Safari
      if (self.$messaging) {
         self.$messaging.onMessage((payload) => {
            const notification = {
               type: payload.notification.title,
               message: payload.notification.body,
               count: false,
            };
            self.$store.commit("app/setNotification", notification);
            self.$store.commit("app/setNotificationCount", self.$store.state.app.notification.count + 1);
         });
      }
   },
   computed: {
      errorStatus() {
         const self = this;
         errorManager.handleErrorStatus(self.$store.state.app.errorStatus);
         return self.$store.state.app.errorStatus;
      },
      notificationStatus() {
         const self = this;
         return self.$store.state.app.notification.active;
      },
      documentViewer() {
         return this.$store.state.app.documentViewer.active;
         // return true;
      },
   },
   methods: {
      fetchCountries() {
         globalService
            .getCountryList()
            .then((countries) => {
               const formattedCountries = countries.map((country) => {
                  country.code = country.code.toLowerCase();
                  return country;
               });

               this.$store.commit("app/setCountryList", formattedCountries);
            })
            .catch((error) => {
               console.error(error);
            });
      },
      handleKeyDown(event) {
         if (event.ctrlKey && event.shiftKey && event.key === "F") {
            const currentUrl = new URL(window.location.href);
            const params = currentUrl.searchParams;

            //fmt conversion

            if (params.get("fmtConversion") === "true") {
               params.set("fmtConversion", "false");
            } else {
               params.set("fmtConversion", "true");
            }

            window.location.href = currentUrl.toString();
         }
      },

      handlerStartpService() {
         var loggedIn = !TokenService.isJwtExpired();

         if (loggedIn == false) {
            globalService.startupService(true, {}).then((response) => {
               // Set Brand
               response.info.originalGsa.cssCode && (this.$constants.brand = response.info.originalGsa);

               icon.value = `favicons/${this.$constants.brand.cssCode}.png`; // change current icon
               title.value = this.$constants.brand.title;

               this.loadPage = true;
            });
         } else {
            this.loadPage = true;
         }
      },
      handlerGoToTop() {
         window.scrollTo(0, 0);
      },
   },
   watch: {
      $route: {
         handler: function (val) {
            if (val.query.fmtConversion === "true") {
               Vue.prototype.$t = function (key) {
                  return key;
               };
            }
         },
      },
      errorStatus: {
         handler: function (newErrorStatus) {
            var self = this;
            self.statusError = newErrorStatus;
         },
         deep: true,
      },

      "$store.state.app.splashScreen": {
         handler: function (status) {
            if (status) {
               setTimeout(() => {
                  document.querySelector("#splash-screen-container").classList.add("remove-splash");
               }, 250);
               setTimeout(() => {
                  document.querySelector("#splash-screen-container").remove();
               }, 700);
            }
         },
         immediate: true,
      },
      "$store.state.user.user.initiated": {
         handler: function (status) {
            if (status) {
               let query = {
                  userId: this.$store.state.user.user.id,
                  application: 2,
               };
               globalService.notificationUnread(query).then((res) => {
                  if (res.result.success) {
                     this.$store.commit("app/setNotificationCount", res.unreadCount);
                  }
               });
            }
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s ease;
// }

// .fade-enter-from,
// .fade-leave-to {
//   opacity: 0;
// }

#app-wrapper {
   height: 100%;

   #gotoTop {
      position: fixed;
      right: 1rem;
      bottom: 2rem;
      background-color: var(--primary);
      border-radius: 50%;
      padding: 0.75rem 0.75rem;

      i {
         filter: brightness(10);
      }

      &:hover {
         background-color: var(--accent-primary);
      }
   }
}
</style>
