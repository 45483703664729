<template>
   <div class="rent-a-car-sales-report-page-container sub-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <div class="container-fluid">
            <div class="row">
               <div class="col gts-box mx-3">
                  <h1 class="sub-page-header">{{ $t("rentACarSalesReport_title") }}</h1>

                  <div class="sub-page-row-wrapper row gy-3">
                     <div class="col-12 col-md-6 col-lg-2">
                        <UISelectBox
                           :key="refreshKey3"
                           :label="$t('rentACarSalesReport_filter_lblReportType')"
                           :options="reportTypes"
                           v-model="filters.reportType"
                           light-theme />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2
                           :key="refreshKey1"
                           :label="$t('rentACarSalesReport_filter_lblFromDate')"
                           :min="filters.rentACarDateFrom.min"
                           :max="filters.rentACarDateFrom.max"
                           v-model="filters.rentACarDateFrom.value" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2
                           :key="refreshKey2"
                           :label="$t('rentACarSalesReport_filter_lblToDate')"
                           :min="filters.rentACarDateTo.min"
                           :max="filters.rentACarDateTo.max"
                           v-model="filters.rentACarDateTo.value" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIMultiSelect
                           :key="rnrStatusListKey"
                           :label="$t('rentACarSalesReport_filter_lblStatus')"
                           :options="rnrStatusList"
                           v-model="filters.rnrStatuses"
                           light-theme
                           allow-search />
                     </div>
                  </div>

                  <div class="sub-page-actions-row row">
                     <div class="sub-pages-actions-wrapper col-12">
                        <UIButton className="default" :text="$t('rentACarSalesReport_filter_btnList')" @click.native="funcRentACarSalesReport" />
                     </div>
                  </div>
               </div>
            </div>

            <!-- Summary & Sale Report -->
            <template v-if="reportLoad">
               <!-- Sale Report Summary -->
               <ReportSummary :entityType="'rentACar'" :summary="summary" />

               <!-- Sale Result Container -->
               <div class="smart-table-wrapper">
                  <SmartTable :options="mainTableOptions" />
               </div>
            </template>
         </div>
      </Main>
   </div>
</template>

<script>
import moment from "moment";
import i18n from "@/fmt/i18n.js";
import Popup from "@/gts-popup.js";
import { reportService } from "@/service/index.js";
import Main from "@/components/unitary/Main.vue";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import SmartTable from "@/components/utils/SmartTable/SmartTableComp.vue";
import UIButton from "@/components/unitary/Button.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIDatePicker2 from "@/components/unitary/form2/UIDatePicker2.vue";
import UIMultiSelect from "@/components/unitary/form2/UIMultiSelect.vue";
import ReportSummary from "@/components/unitary/SalesReportSummary.vue";

export default {
   name: "RentACarSalesReport",
   components: {
      Aside,
      Navbar,
      Main,
      UISelectBox,
      UIDatePicker2,
      UIButton,
      SmartTable,
      UIMultiSelect,
      ReportSummary,
   },

   data() {
      return {
         isFirstSearch: true,
         reportLoad: false,
         refreshKey1: 1,
         refreshKey2: 5,
         refreshKey3: 7,
         rnrStatusListKey: 0,
         reportTypes: this.$GtsDropdownData.EReportRentACarReportType(),
         rnrStatusList: [],
         summary: null,
         filters: {
            reportType: 1,
            rnrStatuses: [2, 3],
            rentACarDateFrom: {
               min: moment().subtract(1, "year").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            rentACarDateTo: {
               min: moment().subtract(1, "year").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
         },
         mainTableOptions: {
            columns: [
               { label: `${i18n.t("rentACarSalesReport_tblMain_thOrderId")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thRnr")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thStatus")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thAgencyName")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thMarkModel")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thPickUpDateTime")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thPickUpLocation")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thDropOffDateTime")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thDropOffLocation")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thSaleDate")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thDriverName")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thDriverSurname")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thTotal")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thAgencyCommission")}`, status: true },
               { label: `${i18n.t("rentACarSalesReport_tblMain_thGrandTotal")}`, status: true },
            ],
            rows: [],
            rules: {
               autoMerge: ["orderId"],
               dominantCell: "orderId",
               showOnMobile: [0, 2, 4, 5, 6],
            },
            options: {
               header: {
                  title: this.$options.name,
                  viewOptions: {
                     status: true,
                     perPage: 50,
                     selects: [
                        { name: "3 Order", value: 3 },
                        { name: "5 Order", value: 5 },
                        { name: "25 Order", value: 25 },
                        { name: "50 Order", value: 50 },
                        { name: "100 Order", value: 100 },
                     ],
                  },
                  viewMode: "default",
                  saleDateIndex: 9,
               },
               footer: {
                  pagination: {
                     pages: [],
                     activepage: 1,
                  },
               },
            },
         },
      };
   },
   mounted() {
      this.updateRnrStatusList();
   },
   methods: {
      updateRnrStatusList() {
         this.rnrStatusList = [];
         for (let i = 1; i < 11; i++) {
            const rowObj = { value: i, name: this.$options.filters.ERacRnrStatus(i) };
            this.rnrStatusList.push(rowObj);
         }
         this.rnrStatusListKey += 1;
         this.$nextTick(() => {
            this.rnrStatusList = this.rnrStatusList.slice();
         });
      },
      getTableColumns() {
         return [
            { label: `${i18n.t("rentACarSalesReport_tblMain_thOrderId")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thRnr")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thStatus")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thAgencyName")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thMarkModel")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thPickUpDateTime")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thPickUpLocation")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thDropOffDateTime")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thDropOffLocation")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thSaleDate")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thDriverName")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thDriverSurname")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thTotal")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thAgencyCommission")}`, status: true },
            { label: `${i18n.t("rentACarSalesReport_tblMain_thGrandTotal")}`, status: true },
         ];
      },
      updateTableColumns() {
         this.mainTableOptions.columns = this.getTableColumns();
      },
      funcRentACarSalesReport() {
         const self = this;

         self.reportLoad = false;
         self.summary = null;
         self.mainTableOptions.rows.length = 0;
         self.$store.commit("app/setIsProgressGifActive", true);

         let request = {
            reportType: self.filters.reportType,
            dateType: 1,
            statuses: self.filters.rnrStatuses,
            date1: self.filters.rentACarDateFrom.value,
            date2: self.filters.rentACarDateTo.value,
         };

         reportService.rentACarSalesReport(request).then((response) => {
            self.$store.commit("app/setIsProgressGifActive", false);

            if (!response.result.success) {
               response.result.service = this.$options.name;
               Popup.result(response.result);
               return;
            }
            this.isFirstSearch = false;
            self.summary = response.summary;

            response.rentacars.map((rental) => {
               const rowObj = {
                  orderId: {
                     text: rental.orderId,
                     class: `gts-badge smart-table-badge-1`,
                     url: `/order/detail/${rental.orderUuid}`,
                  },
                  rnr: rental.rnr,
                  rnrStatus: {
                     text: this.$options.filters.ERacRnrStatus(rental.rnrStatus),
                     class: `rnr-badge gts-badge ERacRnrStatus-${rental.rnrStatus}`,
                  },
                  agencyName: rental.agencyName,
                  vehicleType: rental.markModel,
                  pickUpDateTime: {
                     text: this.$options.filters.FormatterDate(rental.pickUpDateTime),
                     class: "",
                  },
                  pickUpLocation: rental.pickUpLocation,
                  dropOffDateTime: {
                     text: this.$options.filters.FormatterDate(rental.dropOffDateTime),
                     class: "",
                  },
                  dropOffLocation: rental.dropOffLocation,
                  saleDateTime: {
                     text: this.$options.filters.FormatterDate(rental.saleDateTime),
                     class: "",
                  },
                  driverName: rental.driverName,
                  driverSurname: rental.driverSurname,
                  total: {
                     text: this.$options.filters.price(rental.total),
                     class: "price-display",
                  },
                  agencyCommission: {
                     text: this.$options.filters.price(rental.agencyCommission),
                     class: "price-display",
                  },
                  grandTotal: {
                     text: this.$options.filters.price(rental.grandTotal),
                     class: "price-display",
                  },
               };
               self.mainTableOptions.rows.push(rowObj);
            });
            self.reportLoad = true;
         });
      },
      funcRefreshKeys() {
         this.refreshKey1++;
         this.refreshKey2++;
         this.refreshKey3++;
      },
   },
   watch: {
      "$i18n.locale"() {
         this.reportTypes = this.$GtsDropdownData.EReportRentACarReportType();
         this.updateRnrStatusList();
         if (this.isFirstSearch == false) {
            this.funcRentACarSalesReport();
         }
         this.updateTableColumns();
         this.funcRefreshKeys();
      },
   },
};
</script>

<style lang="scss" scoped>
.page-container {
   .page-inner {
      width: 90%;
      margin: auto;
      .page-form-row {
         display: grid;
         grid-template-columns: repeat(6, 1fr);
         grid-column-gap: 1rem;
      }
      .page-action-row {
         margin-top: 1rem;
         display: flex;
         justify-content: flex-end;
      }
   }
}
.rent-a-car-sales-report {
   .summary-row {
      grid-template-columns: repeat(5, 1fr) !important;
   }
   .summary-col {
      padding: 0.125rem 1rem !important;
   }
}
</style>
