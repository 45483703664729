<template>
   <div id="flight-search-results-wrapper">
      <PackageCompTestVue :packageOffers="packageOffers" v-if="false" />
      <AirlineMatrix />

      <div class="flight-legs-wrapper row gx-2 gx-lg-3">
         <div
            class="flight-leg-wrapper col"
            :class="[searchResponse?.searchTripType == 1 ? 'col-12' : 'col-12 col-md-6 flight-leg-duple-view', 'flight-leg-' + legIndex]"
            data-has-selection="false"
            v-for="(leg, legIndex) in searchResponse.legs"
            :key="legIndex">
            <FlightLeg
               :leg="leg"
               :channels="searchResponse.channels"
               :family-prices="familyPrices"
               :trip-type="searchResponse?.searchTripType"
               :airports="searchResponse.airports" />
         </div>
      </div>
   </div>
</template>

<script>
//PackageCompTestVue silinecek

import AirlineMatrix from "@/components/structural/flight/search/AirlineMatrix.vue";
import FlightLeg from "@/components/structural/flight/search/search-results/FlightLeg.vue";
import PackageCompTestVue from "@/pages/PackageCompTest.vue";

export default {
   name: "FlightSearchResults",
   props: {
      searchResponse: Object,
      familyPrices: Object,
      packageOffers: Array,
   },
   components: {
      FlightLeg,
      AirlineMatrix,
      PackageCompTestVue,
   },

   mounted() {
      //Slide animation after search response
      if (document.querySelector("#airline-matrix-container")) {
         window.scrollTo(0, document.querySelector("#airline-matrix-container")?.getBoundingClientRect()?.top);
      }
   },
};
</script>

<style lang="scss" scoped>
#flight-search-results-wrapper {
   margin-top: 1rem;
   margin-top: 1rem;

   .flight-legs-wrapper {
      .flight-leg-wrapper {
         &.flight-leg-duple-view {
            ::v-deep .flightcard-container {
               @media (min-width: 768px) {
                  padding: 1rem 0.75rem;
               }

               .flight-infographic-container {
                  // max-width: 260px;
               }
            }
         }
         &.flight-leg-sticky {
            @media (min-width: 576px) {
               position: sticky;
               top: 100px;
               height: max-content;
            }
            @media (min-width: 992px) {
               position: sticky;
               top: 1rem;
               height: max-content;
            }
         }
         .flight-bg {
            position: relative;
            background-color: red;

            @keyframes noFlight {
               from {
                  opacity: 0;
               }
               to {
                  opacity: 1;
               }
            }
            .no-flight-warning {
               padding: 0.5rem;
               opacity: 0;
               position: absolute;
               top: 0px;
               align-items: center;
               justify-content: center;
               display: flex;
               flex-direction: row;
               @keyframes noFlight {
                  from {
                     opacity: 0;
                  }
                  to {
                     opacity: 1;
                  }
               }
               .no-flight-warning {
                  padding: 0.5rem;
                  opacity: 0;
                  position: absolute;
                  top: 0px;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  flex-direction: row;

                  &.warning-active {
                     animation-name: noFlight;
                     animation-timing-function: ease-in-out;
                     animation-duration: 0.25s;
                     opacity: 1;
                     display: flex;
                     background-color: #fff;
                     width: 100%;
                  }
                  &.warning-active {
                     animation-name: noFlight;
                     animation-timing-function: ease-in-out;
                     animation-duration: 0.25s;
                     opacity: 1;
                     display: flex;
                     background-color: #fff;
                     width: 100%;
                  }

                  span {
                     font-size: 2rem;
                     font-weight: 500;
                     color: var(--primary-pastel);
                  }
                  .sad-face {
                     font-weight: 800;
                     margin-right: 0.75rem;
                     // display: nones;
                  }
                  .no-flight-message {
                  }
                  span {
                     font-size: 2rem;
                     font-weight: 500;
                     color: var(--primary-pastel);
                  }
                  .sad-face {
                     font-weight: 800;
                     margin-right: 0.75rem;
                     // display: nones;
                  }
                  .no-flight-message {
                  }
               }
            }
            .flight-card-container {
               background-color: #fff;
               padding: 0.5rem;
               display: grid;
            }
            .flight-card-container {
               background-color: #fff;
               padding: 0.5rem;
               display: grid;

               @media (min-width: 576px) {
                  padding: 1rem;
                  @media (min-width: 576px) {
                     padding: 1rem;
                  }
               }
            }
         }
         #lazy-loader {
            position: fixed;
            bottom: 1.5rem;
            left: 0;
            right: 0;
            margin: auto;
            width: 200px;
            padding: 0.5rem 1rem;
            border-radius: 3rem;
            background-color: #ffffff;
         }
      }
   }
}
</style>
