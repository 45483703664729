<template>
   <div class="flight-leg-container">
      <section class="flight-leg-header-container">
         <FlightLegHeader
            @flight-filters="handleFilterFlights"
            @clear-selection="handlerClearSelection"
            :leg-route="leg.route"
            :leg-index="leg.index"
            :leg-date="leg.date" />
      </section>

      <div class="no-flight-warning" v-if="flights.length == 0">
         {{ $t("flightsResults_noFlightWarning") }}
      </div>

      <div class="flightcard-container" v-else>
         <template>
            <transition-group name="fade" mode="out-in">
               <FlightCard
                  v-for="(flight, flightIndex) in flights"
                  :key="flightIndex"
                  :flight-index="flightIndex"
                  :flight="flight"
                  :leg-index="leg.index"
                  @selected-flight="handlerSelectedFlight" />
            </transition-group>
         </template>
      </div>
   </div>
</template>

<script>
import FlightLegHeader from "@/components/structural/flight/search/search-results/FlightLegHeader.vue";
import FlightCard from "@/components/structural/flight/search/search-results/FlightCard.vue";
import { baggageInformations, flightDuration, segmentGenerator } from "@/helper/flight.js";
export default {
   name: "FlightLeg",
   props: {
      leg: Object,
      channels: Object,
      tripType: Number,
      familyPrices: Object,
      airports: Object,
   },
   components: {
      FlightLegHeader,
      FlightCard,
   },

   data() {
      return {
         flights: [],
         flightList: {},
         filterBy: "priceincrease",
         legHasSelection: false,
      };
   },
   computed: {
      Filters() {
         return this.$store.state.flight.filters[this.leg.index];
      },
   },

   methods: {
      handlerSelectedFlight(emmitedSelection) {
         this.flights.forEach((flight) => {
            if (flight.identifier != emmitedSelection.identifier) {
               flight.isSelected = false;
               flight.isHiddenFromUI = true;
            }
         });
         this.legHasSelection = true;
         this.flights[emmitedSelection.index].isSelected = true;
         //Selected Flight Informations
         const selection = {
            index: this.leg.index,
            identifier: emmitedSelection.identifier,
         };
         this.$store.commit("flight/updateBasket", selection);

         // if (this.flights[selection.index].identifier == selection.identifier) {
         //    this.flights[selection.index].isSelected = true;
         //    //Selected Flight Informations
         //    const selection = {
         //       index: this.leg.index,
         //       identifier: selection.identifier,
         //    };
         //    this.$store.commit("flight/updateBasket", selection);
         // }
      },
      handlerIsCharter(channels, channelId) {
         return channels[channelId]?.providerType == 3 ? true : false;
      },
      handlerSortFlights() {
         switch (this.filterBy) {
            case "priceincrease":
               this.flights.sort((a, b) => {
                  if (a.price == 0) {
                     return 1;
                  } else {
                     if (a.price < b.price && a.price != 0 && b.price != 0) return -1;
                     if (a.price > b.price && a.price != 0 && b.price != 0) return 1;
                     return 0;
                  }
               });
               break;
            case "pricedecrease":
               this.flights.sort((a, b) => {
                  if (a.price == 0) {
                     return 1;
                  } else {
                     if (a.price > b.price && a.price != 0 && b.price != 0) return -1;
                     if (a.price < b.price && a.price != 0 && b.price != 0) return 1;
                     return 0;
                  }
               });
               break;

            case "departureincrease":
               this.flights.sort((a, b) => (a.segments[0].timeFilter < b.segments[0].timeFilter ? -1 : 1));
               break;
            case "departuredecrease":
               this.flights.sort((a, b) => (a.segments[0].timeFilter > b.segments[0].timeFilter ? -1 : 1));
               break;
            case "arrivaltimeincrease":
               this.flights.sort((a, b) =>
                  a.segments[Object.keys(a.segments).length - 1].timeFilter < b.segments[Object.keys(b.segments).length - 1].timeFilter ? -1 : 1
               );

               break;
            case "arrivaltimedecrease":
               this.flights.sort((a, b) =>
                  a.segments[Object.keys(a.segments).length - 1].timeFilter > b.segments[Object.keys(b.segments).length - 1].timeFilter ? -1 : 1
               );
               break;
            case "totaltraveltimeincrease":
               this.flights.sort((a, b) => (a.travelTimeFilter < b.travelTimeFilter ? -1 : 1));
               break;
            case "totaltraveltimedecrease":
               this.flights.sort((a, b) => (a.travelTimeFilter > b.travelTimeFilter ? -1 : 1));
               break;
            case "totalwaitingtimeincrease":
               this.flights.sort((a, b) => {
                  if (a.transferTimeFilter.length != 10) {
                     return a.transferTimeFilter < b.transferTimeFilter ? -1 : 1;
                  }
               });
               break;
            case "totalwaitingtimedecrease":
               this.flights.sort((a, b) => {
                  if (a.transferTimeFilter.length != 10) {
                     return a.transferTimeFilter > b.transferTimeFilter ? -1 : 1;
                  }
               });
               break;
            case "transferincrease":
               this.flights.sort((a, b) => (a.connection < b.connection ? -1 : 1));
               break;
            case "transferdecrease":
               this.flights.sort((a, b) => (a.connection > b.connection ? -1 : 1));
               break;
         }
      },
      handleFilterFlights(filterBy) {
         this.filterBy = filterBy.filterName;
         this.handlerSortFlights();
      },
      handlerClearSelection() {
         if (this.legHasSelection) {
            this.$store.commit("app/setIsProgressGifActive", true);
            this.flights.forEach((flight) => {
               flight.isSelected = false;
               flight.isHiddenFromUI = false;
            });
            this.legHasSelection = false;
            this.$store.commit("flight/deleteItemFromBasket", this.leg.index);
            localStorage.setItem("mobileBasketAutoShown", false);
         }

         // //if familyPrices response is empty
         // document.querySelectorAll(".has-family-price").forEach((flightcard) => {
         //    flightcard.classList.remove("not-has-family-price");
         // });
         // // clear Selected radio Button
         // document.querySelectorAll(`[data-leg="${this.leg.index}"]`).forEach((flightcard) => {
         //    flightcard.classList.remove("flightcard-collapsed");
         //    flightcard.classList.remove("lazy-loaded-collapsed-leg");

         //    flightcard.querySelector(".flightcard-selection-wrapper").classList.remove("is-selected");
         // });

         // // //remove sticky
         // document.querySelector(`.flight-leg-${this.leg.index}`).classList.remove("flight-leg-sticky");
         // document.querySelector(`.flight-leg-${this.leg.index}`).removeAttribute("data-has-selection");

         // //Update mobile basket status
         // localStorage.setItem("mobileBasketAutoShown", false);

         // // // update Basket
         // this.$store.commit("flight/deleteItemFromBasket", this.leg.index);
      },

      handlerFlights2Arr() {
         //if search request has multiple destinations highlight second chip
         const searchRequestLegs = Object.entries(this.$store.state.flight.search.request.legs);
         const multipleCitiesObj = {};
         const highlightedDestinations = {};

         searchRequestLegs.forEach(([key, leg]) => {
            key;
            if (leg.fromDestinations.length == 2) {
               const fromDestinationCity = leg.fromDestinations[1].replace("!", "");
               multipleCitiesObj[fromDestinationCity] = fromDestinationCity;
            }
            if (leg.toDestinations.length == 2) {
               const toDestinationCity = leg.toDestinations[1].replace("!", "");
               multipleCitiesObj[toDestinationCity] = toDestinationCity;
            }
         });

         Object.entries(this.airports).forEach(([key, entry]) => {
            key;
            const isGroupCodeInList = Object.prototype.hasOwnProperty.call(multipleCitiesObj, entry.groupCode);
            if (isGroupCodeInList) {
               highlightedDestinations[entry.code] = entry.code;
            }
         });

         Object.entries(this.leg.flights).map(([key, value]) => {
            //If flight is already added just skip//
            const isAlreadyOnList = Object.prototype.hasOwnProperty.call(this.flightList, key);
            if (!isAlreadyOnList) {
               this.$set(this.flightList, key, value.marketingAirlineCode);
               const flightObj = {
                  isSelected: false,
                  isHiddenFromUI: this.legHasSelection ? true : false,
                  channel: this.channels[value.channelId]?.channelName,
                  identifier: value.identifier,
                  debugger: value.debugger,
                  legIndex: this.leg.index,
                  cabinType: value.cabinType,
                  marketingAirline: this.$i18n.t(`airlineEnum_${value.marketingAirlineCode}`),
                  operatingAirline: this.$i18n.t(`airlineEnum_${value.operatingAirlineCode}`),
                  marketingAirlineCode: value.marketingAirlineCode,
                  operatingAirlineCode: value.operatingAirlineCode,
                  baggage: baggageInformations(value.baggageInfo),
                  isCharter: this.channels[value.channelId]?.providerType == 3,
                  leftSeat: value.leftSeat,
                  travelTime: flightDuration(value.travelTime),
                  travelTimeFilter: value.travelTime,
                  transferTime: flightDuration(value.transferTime),
                  transferTimeFilter: value.transferTime,
                  segments: segmentGenerator(value.segments, highlightedDestinations),
                  details: {
                     segments: value.segments,
                     route: this.leg.route,
                     airports: this.airports,
                  },
                  connection: Object.keys(value.segments).length - 1,
                  flightNumber: value.segments[0].flightNumber,
                  price: value.pricePerAdult,
                  familyPrice: null,
                  stopCount:
                     value.segments.length - 1 <= 0
                        ? this.$i18n.t("searchFiltersFlight_noneStop")
                        : value.segments.length - 1 + " " + this.$i18n.t("searchFiltersFlight_stop"),
               };
               this.flights.push(flightObj);
            }
         });

         //if legHasSelection do not sort flights!
         if (!this.legHasSelection) {
            this.handlerSortFlights();
         }
      },
   },

   watch: {
      leg: {
         handler: function () {
            //Lazy load updates detect and call handlerFlights2Arr()
            this.handlerFlights2Arr();
         },
         immediate: true,
         deep: true,
      },

      familyPrices: {
         handler: function (familyPrices) {
            this.flights.forEach((flight) => {
               flight.familyPrice = null;
            });

            Object.entries(familyPrices).forEach(([key, value]) => {
               this.flights.forEach((flight, index) => {
                  if (key == flight.identifier) {
                     this.flights[index].familyPrice = value;
                  }
               });
            });
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.flight-leg-container {
   .flightcard-container {
      background-color: #fff;
      padding: 0.5rem 0.125rem;
      display: flex;
      flex-direction: column;

      @media (min-width: 576px) {
         padding: 1rem;
      }
   }

   .no-flight-warning {
      background: white;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
   }
}
</style>
