<template>
   <div id="search-tab-container" :style="isPackage ? 'height:100vh;' : ''">
      <div class="search-tabs">
         <!-- FLIGHT SEARCH -->
         <router-link :disabled="!roles.includes(1)" :event="roles.includes(1) ? 'click' : ''" to="/flight">
            <div class="search-tab search-tab-flight" :class="activeTab == 1 ? 'active-tab' : ''">
               <i class="gts-icon icon-tab-flight-search d-none d-md-block"></i>
               <span class="search-tab-name">{{ $t("common_searchFlight") }}</span>
            </div>
         </router-link>

         <!-- HOTEL SEARCH -->
         <router-link :disabled="!roles.includes(2)" :event="roles.includes(2) ? 'click' : ''" to="/hotel">
            <div class="search-tab search-tab-hotel" :class="activeTab == 2 ? 'active-tab' : ''">
               <i class="gts-icon icon-tab-hotel-search d-none d-md-block"></i>
               <span class="search-tab-name">{{ $t("common_searchHotel") }}</span>
            </div>
         </router-link>

         <!-- TRANSFER SEARCH -->
         <router-link :disabled="!roles.includes(3)" :event="roles.includes(3) ? 'click' : ''" to="/transfer">
            <div class="search-tab search-tab-transfer" :class="activeTab == 3 ? 'active-tab' : ''">
               <i class="gts-icon icon-tab-transfer-search d-none d-md-block"></i>
               <span class="search-tab-name">{{ $t("common_searchTransfer") }}</span>
            </div>
         </router-link>

         <!-- RENT A CAR SEARCH -->
         <router-link v-if="roles.includes(22)" :event="roles.includes(22) ? 'click' : ''" to="/rentacar">
            <div class="search-tab search-tab-rentacar" :class="activeTab == 4 ? 'active-tab' : ''">
               <i class="gts-icon icon-tab-rentacar-search d-none d-md-block"></i>
               <span class="search-tab-name">{{ $t("common_searchRentACar") }}</span>
            </div>
         </router-link>

         <!-- Schimetterling-->
         <router-link :event="roles.includes(25) ? 'click' : ''" to="/packages" @click.native="handlerPackagesPopup">
            <div class="search-tab search-tab-schimetterling" :class="activeTab == 5 ? 'active-tab' : ''">
               <i class="gts-icon icon-tab-schimetterling-search d-none d-md-block"></i>
               <span class="search-tab-name">{{ $t("common_searchPackages") }}</span>
            </div>
         </router-link>
      </div>

      <div class="search-tab-content">
         <template v-if="activeTab == 1">
            <FlightSearchForm />
         </template>
         <template v-else-if="activeTab == 2">
            <!-- <HotelSearchForm @sticky-bar-status="handleScroll" /> -->
            <HotelSearchForm />
         </template>
         <template v-else-if="activeTab == 3">
            <TransferSearchForm />
         </template>
         <template v-else-if="activeTab == 4">
            <RentACarSearchForm />
         </template>
      </div>
   </div>
</template>

<script>
import Popup from "@/gts-popup.js";
import FlightSearchForm from "@/components/structural/flight/search/FlightSearchForm.vue";
import HotelSearchForm from "@/components/structural/hotel/common/HotelSearchForm.vue";
import TransferSearchForm from "@/components/structural/transfer/search/TransferSearchForm.vue";
import RentACarSearchForm from "@/components/structural/rentacar/search/RentACarSearchForm.vue";

export default {
   name: "SearchTab",
   props: {
      activeTab: Number,
      isPackage: Boolean,
   },
   components: {
      FlightSearchForm,
      HotelSearchForm,
      TransferSearchForm,
      RentACarSearchForm,
   },
   data() {
      return {
         roles: [],
      };
   },
   mounted() {
      this.roles = JSON.parse(localStorage.getItem("auths"));
   },
   methods: {
      handlerPackagesPopup() {
         Popup.info(this.$i18n.t("Popup_lblPackagesTitle"), this.$i18n.t("Popup_lblPackagesContent"));
      },
   },
};
</script>

<style lang="scss" scoped>
#search-tab-container {
   position: relative;
   background: var(--c-primary);
   padding: 4rem 0.75rem;
   margin: 0rem;
   border-radius: 0.5rem;

   &.--stickyModeOn {
      position: sticky;
      top: 0;
      z-index: 10;
      @media (max-width: 991px) {
         top: 82px;
      }
   }
   &.sticky {
      padding: 0;
      border-radius: 58px;
      .search-tabs {
         display: none;
      }
   }

   @media (min-width: 576px) {
      margin: 1.5rem 0rem;
      padding: 3rem 2rem;
   }

   .search-tabs {
      display: flex;
      position: absolute;
      bottom: 100%;
      align-items: flex-end;
      .search-tab {
         display: flex;
         align-items: center;
         background-color: #fff;
         margin: 0 0.35rem;
         padding: 0.25rem 0.85rem;
         // padding-top: 0.5rem;
         border-radius: 0.5rem;
         border-bottom-left-radius: 0;
         border-bottom-right-radius: 0;
         border-bottom: 1px solid #ebebeb;
         height: 50px !important;

         &:first-child {
            margin-left: 0;
         }

         i {
            // width: 28px !important;

            &.icon-tab-transfer-search {
               width: 46px !important;
            }
         }

         span {
            margin-left: 0.5rem;
            color: #556b88;
            font-weight: 500;
         }

         &.active-tab {
            padding: 0.25rem 0.85rem;
            padding-top: 0.75rem;

            &.search-tab-flight {
               background: var(--primary-flight);
            }
            &.search-tab-hotel {
               background: var(--primary-hotel);
            }
            &.search-tab-transfer {
               background: var(--primary-transfer);
            }
            &.search-tab-rentacar {
               background: var(--primary-rentacar);
            }
            &.search-tab-schimetterling {
               background: #e65c8a;
            }

            span.search-tab-name {
               color: #fff;
            }
            i.gts-icon {
               filter: brightness(10);
            }
         }

         &.search-tab-schimetterling {
            i.gts-icon {
               height: 60px !important;
               width: 70px !important;
            }
         }
      }
   }
   .search-tab-content {
      margin-top: 0rem;
      @media (min-width: 576px) {
         // margin-top: 1rem;
      }
   }
}
</style>
