import store from "@/store/index.js";
import router from "@/router";
import Popup from "@/gts-popup.js";
import { flightService } from "@/service/index.js";
import { hotelService } from "@/service/index.js";
import { transferService } from "@/service/index.js";
import { rentacarService } from "@/service/index.js";
import { financeService } from "@/service/index.js";
import { nextTickControl } from "./nexTick";
import { setCookie } from "@/utils.js";
import { showProgressGif, hideProgressGif } from "./Helpers/paymentHelper.js";

function openPopupElement(customUrl = "/loading") {
   var width = 500;
   var height = 500;
   var screenWidth = window.screen.width;
   var screenHeight = window.screen.height;
   var left = (screenWidth - width) / 2;
   var top = (screenHeight - height) / 2;
   return window.open(customUrl, "paymentPostContentForm", `location=no, left=${left}, top=${top}, width=${width}, height=${height}`);
}

function handlePaymentContent(paymentPostContent, windowPopupElement) {
   if (paymentPostContent.startsWith("http")) {
      windowPopupElement.location.href = paymentPostContent;
   } else {
      windowPopupElement.document.write(paymentPostContent);
   }
}

function createRequestObject(type, req) {
   switch (type) {
      case "hotel":
         return { ...req, rooms: req.rooms };
      case "transfer":
         return { ...req, travellers: req.travellers };
      case "flightBaggage":
      case "flightSeat":
      case "flightReissue":
         return { ...req };
      case "flightDirect":
         return { ...req, passengers: req.passengers };
      case "rentACar":
         return { ...req, paymentInfo: req.paymentInfo };
      case "finance":
         return { ...req };
      default:
         return req;
   }
}
function funcTicketPartialPayHotel(self, req) {
   let request = createRequestObject("hotel", req);
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_${self.paymentEntry.trxTypeLabel}_HOTEL`);
   let windowPopupElement;

   showProgressGif(msg);

   if (req.payment.paymentType != 1 && req.trxType == 3) {
      windowPopupElement = openPopupElement();
   }
   hotelService.partialOrderCheckout(request).then((response) => {
      setCookie("rooms", JSON.stringify(request.rooms), 0, 1);
      hideProgressGif();
      response.result.service = `${self.paymentEntry.trxTypeLabel}_HOTEL`;
      if (!response.result.success) {
         Popup.result(response.result);
         self.paymentUuid = null;
         self.callback3D = false;
         windowPopupElement && windowPopupElement.close();
         return;
      } else {
         if (response.result.subCode == "PAYMENT_POST") {
            self.paymentPostContent = response.reservationResponse.paymentPostContent;
            self.paymentUuid = response.reservationResponse.paymentUuid;
            handlePaymentContent(response.reservationResponse.paymentPostContent, windowPopupElement);
            nextTickControl(self, windowPopupElement);
         } else {
            if (windowPopupElement) {
               windowPopupElement.close();
            }
            const popupCb = () => {
               router.push(`/order/detail/${response.reservationResponse.orderUuid}?issued=true`);
            };
            Popup.result(response.result, popupCb).then(() => {
               router.push(`/order/detail/${response.reservationResponse.orderUuid}?issued=true`);
            });
         }
      }
   });
}
function funcTicketDirectHotel(self, req) {
   let request = createRequestObject("hotel", req);
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_${self.paymentEntry.trxTypeLabel}_HOTEL`);
   let windowPopupElement;

   showProgressGif(msg);

   if (req.payment.paymentType != 1 && req.trxType == 3) {
      windowPopupElement = openPopupElement();
   }
   hotelService.hotelCheckout(request).then((response) => {
      setCookie("rooms", JSON.stringify(request.rooms), 0, 1);
      hideProgressGif();
      response.result.service = `${self.paymentEntry.trxTypeLabel}_HOTEL`;
      if (!response.result.success) {
         Popup.result(response.result);
         self.paymentUuid = null;
         self.callback3D = false;
         windowPopupElement && windowPopupElement.close();
         return;
      } else {
         if (response.result.subCode == "PAYMENT_POST") {
            self.paymentPostContent = response.reservationResponse.paymentPostContent;
            self.paymentUuid = response.reservationResponse.paymentUuid;
            handlePaymentContent(response.reservationResponse.paymentPostContent, windowPopupElement);
            nextTickControl(self, windowPopupElement);
         } else {
            if (windowPopupElement) {
               windowPopupElement.close();
            }
            const popupCb = () => {
               router.push(`/order/detail/${response.reservationResponse.orderUuid}?issued=true`);
            };
            Popup.result(response.result, popupCb).then(() => {
               router.push(`/order/detail/${response.reservationResponse.orderUuid}?issued=true`);
            });
         }
      }
   });
}

function funcTicketDirectTransfer(self, req) {
   let request = createRequestObject("transfer", req);
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_${self.paymentEntry.trxTypeLabel}_TRANSFER`);
   let windowPopupElement;

   showProgressGif(msg);

   if (req.payment.paymentType != 1 && !self.callback3D) {
      windowPopupElement = openPopupElement();
   }
   transferService.transferCheckout(request).then((response) => {
      hideProgressGif();
      response.result.service = `${self.paymentEntry.trxTypeLabel}_TRANSFER`;
      setCookie("travelerData", JSON.stringify(request.travellers), 0, 1);
      if (!response.result.success) {
         Popup.result(response.result);
         self.paymentUuid = null;
         self.callback3D = false;
         windowPopupElement && windowPopupElement.close();
         return;
      } else {
         if (response.result.subCode == "PAYMENT_POST") {
            self.paymentPostContent = response.reservationResponse.paymentPostContent;
            self.paymentUuid = response.reservationResponse.paymentUuid;
            handlePaymentContent(response.reservationResponse.paymentPostContent, windowPopupElement);
            nextTickControl(self, windowPopupElement);
         } else {
            if (windowPopupElement) {
               windowPopupElement.close();
            }
            Popup.result(response.result).then(() => {
               router.push(`/order/detail/${response.reservationResponse.orderUuid}?issued=true`);
            });
         }
      }
   });
}

function funcTicketBaggages(self, req) {
   let request = createRequestObject("flightBaggage", req);
   let windowPopupElement;
   self.$refs.paymentChoices.loadingWithSpinner = true;

   if (req.paymentType != 1 && !self.callback3D) {
      windowPopupElement = openPopupElement();
   }
   flightService.ticketBaggages(request).then((response) => {
      if (!response.result.success) {
         if (windowPopupElement) {
            windowPopupElement.close();
         }
         self.paymentUuid = null;
         self.callback3D = false;
         self.$refs.paymentChoices.loadingWithSpinner = false;
      }
      if (response.result.success) {
         if (response.result.subCode == "PAYMENT_POST") {
            self.paymentPostContent = response.paymentPostContent;
            self.paymentUuid = response.paymentUuid;
            handlePaymentContent(response.paymentPostContent, windowPopupElement);
            nextTickControl(self, windowPopupElement);
         } else if (response.result.subCode == "OK") {
            self.$parent.result = {
               allowed: true,
               success: true,
               type: 1,
               title: "ancillariesServicesModal_baggageSuccessTitle",
               message: self.$t("ancillariesServicesModal_baggageContentSuccess").replace("{ORDER}", `<b>${self.$parent?.orderId}</b>`),
            };
            self.$parent.activeStep = 2;
            windowPopupElement.close();
         }
      } else {
         if (windowPopupElement) {
            windowPopupElement.close();
         }
         self.$parent.result = {
            allowed: true,
            success: false,
            type: 1,
            title: "ancillariesServicesModal_baggageFailedTitle",
            message: self.$t("ancillariesServicesModal_baggeFailedMessage").replace("{ORDER}", `<b>${self.$parent?.orderId}</b>`),
         };
         self.$parent.activeStep = 2;
      }
   });
}

function funcTicketSeats(self, req) {
   let request = createRequestObject("flightSeat", req);
   let windowPopupElement;
   self.$refs.paymentChoices.loadingWithSpinner = true;
   self.$store.commit("seatmap/setPaymentStatus", true);

   if (req.paymentType != 1 && !self.callback3D) {
      windowPopupElement = openPopupElement();
   }

   flightService.ancillaryTicketSeats(request).then((response) => {
      self.$store.commit("seatmap/setPaymentStatus", false);
      if (!response.result.success) {
         if (windowPopupElement) {
            windowPopupElement.close();
         }
         self.paymentUuid = null;
         self.callback3D = false;
         self.$refs.paymentChoices.loadingWithSpinner = false;
      }
      if (response.result.success) {
         if (response.result.subCode == "PAYMENT_POST") {
            self.paymentPostContent = response.paymentPostContent;
            self.paymentUuid = response.paymentUuid;
            handlePaymentContent(response.paymentPostContent, windowPopupElement);
            nextTickControl(self, windowPopupElement);
         }
      } else {
         if (windowPopupElement) {
            windowPopupElement.close();
         }
      }

      self.$store.commit("seatmap/setResult", response.result);
   });
}

function funcTicketReissue(self, req) {
   let request = createRequestObject("flightReissue", req);
   let windowPopupElement;
   self.$refs.paymentChoices.loadingWithSpinner = true;

   if (req.paymentType != 1 && !self.callback3D) {
      windowPopupElement = openPopupElement();
   }
   flightService.ancillaryReissueConfirm(request).then((response) => {
      if (!response.result.success) {
         if (windowPopupElement) {
            windowPopupElement.close();
         }
         self.paymentUuid = null;
         self.callback3D = false;
         self.$refs.paymentChoices.loadingWithSpinner = false;
      }
      if (response.result.success) {
         if (response.result.subCode == "PAYMENT_POST") {
            self.paymentPostContent = response.paymentPostContent;
            self.paymentUuid = response.paymentUuid;

            handlePaymentContent(response.paymentPostContent, windowPopupElement);

            nextTickControl(self, windowPopupElement);
         } else if (response.result.subCode == "OK") {
            self.$parent.result = {
               allowed: true,
               success: true,
               type: 5,
               title: "ancillariesServicesModal_reissueSuccessTitle",
               message: self.$t("ancillariesServicesModal_reissueSuccessText"),
            };
            self.$parent.activeStep = 3;
            windowPopupElement.close();
         }
      } else {
         if (windowPopupElement) {
            windowPopupElement.close();
         }
         self.$parent.result = {
            allowed: true,
            success: false,
            type: 5,
            title: "ancillariesServicesModal_reissueErrorTitle",
            message: self.$t("ancillariesServicesModal_reissueErrorText"),
         };
         self.$parent.activeStep = 3;
      }
   });
}

function funcTicketDirectFlight(self, req) {
   let request = createRequestObject("flightDirect", req);
   showProgressGif(self.$i18n.t(`flightCheckoutPayment_transectionProgress`));
   let windowPopupElement;

   if (req.payment.paymentType != 1 && req.trxType == 3) {
      windowPopupElement = openPopupElement();
   }

   flightService.checkout(request).then((response) => {
      hideProgressGif();
      setCookie("paxData", JSON.stringify(request.passengers), 0, 1);

      self.$store.commit("flight/updateCheckoutRequest", response.checkoutRequest);
      self.$store.commit("flight/updateCheckoutResponse", response.checkoutResponse);

      if (!response.result.success) {
         if (windowPopupElement) {
            windowPopupElement.close();
         }
         Popup.result(response.result);

         self.paymentUuid = null;
         self.callback3D = false;
      }

      if (response.completed) {
         self.paymentUuid = null;
         self.callback3D = false;
         if (response.checkoutRequest.trxType == 2) {
            if (response.result.success) {
               router.push(`/order/detail/${response.reserveOrTicketResponse.orderUuid}?reserved=true`);
            } else {
               router.push(`/order/detail/${response.reserveOrTicketResponse.orderUuid}`);
            }
         } else if (response.checkoutRequest.trxType == 3 || response.checkoutRequest.trxType == 6) {
            router.push(`/order/detail/${response.reserveOrTicketResponse.orderUuid}?issued=true`);
         } else {
            router.push(`/order/detail/${response.reserveOrTicketResponse.orderUuid}`);
         }
      } else if (response.result.subCode == "PAYMENT_POST") {
         self.paymentPostContent = response.reserveOrTicketResponse.paymentPostContent;
         self.paymentUuid = response.checkoutResponse.checkoutUuid;

         handlePaymentContent(response.reserveOrTicketResponse.paymentPostContent, windowPopupElement);

         nextTickControl(self, windowPopupElement);
      } else if (response.result.subCode == "PRICE_DIFFERENCE") {
         store.state.flight.checkout.pricedifference.status = true;
         store.commit("flight/updateCheckoutResponse", response.checkoutResponse);
         store.commit("flight/updateCheckoutRequest", response.checkoutRequest);
         store.commit("flight/updateCheckoutUuid", response.checkoutUuid);
         store.state.flight.checkout.pricedifference.value = response.reserveOrTicketResponse.priceChangeAfterReserved;
      } else if (response.completed == true && response.reserveOrTicketResponse.orderUuid != null) {
         router.push(`/order/detail/${response.reserveOrTicketResponse.orderUuid}`);
      }
   });
}

function funcBalanceUpdate(self, req) {
   console.log("##### BALANCE UPDATE FUNC INITIALIZED");
   let request = createRequestObject("finance", req);
   let windowPopupElement;

   let msg = self.$i18n.t(`${self.$options.name}_progressGif_${self.paymentEntry.trxTypeLabel}_FINANCE`);
   showProgressGif(msg);

   if (req.paymentType != 1 && !self.callback3D) {
      windowPopupElement = openPopupElement();
   }
   financeService.updateBalance(request).then((response) => {
      hideProgressGif();
      if (!response.result.success) {
         Popup.result(response.result);
         self.paymentUuid = null;
         self.callback3D = false;
         windowPopupElement && windowPopupElement.close();
         return;
      }
      if (response.result.subCode == "PAYMENT_POST") {
         self.paymentPostContent = response.paymentPostContent;
         self.paymentUuid = response.paymentUuid;
         handlePaymentContent(response.paymentPostContent, windowPopupElement);
         nextTickControl(self, windowPopupElement);
      } else {
         windowPopupElement && windowPopupElement.close();
         router.push({ name: "AccTransactionList", query: { deposit: true } });
      }
   });
}

function funcTicketDirectRentACar(self, req) {
   let request = createRequestObject("rentACar", req);
   showProgressGif(self.$i18n.t(`flightCheckoutPayment_transectionProgress`));
   let windowPopupElement;

   if (req.paymentInfo.paymentType != 1 && req.trxType == 3) {
      windowPopupElement = openPopupElement();
   }

   rentacarService.rentacarCheckout(request).then((response) => {
      windowPopupElement;
      hideProgressGif();
      if (!response.result.success) {
         if (windowPopupElement) {
            windowPopupElement.close();
         }
         Popup.result(response.result);

         self.paymentUuid = null;
         self.callback3D = false;
      }

      if (response.completed) {
         self.paymentUuid = null;
         self.callback3D = false;
         if (response.trxType == 2) {
            if (response.result.success) {
               router.push(`/order/detail/${response.orderUuid}?reserved=true`);
            } else {
               router.push(`/order/detail/${response.orderUuid}`);
            }
         } else if (response.trxType == 3 || response.trxType == 6) {
            router.push(`/order/detail/${response.orderUuid}?issued=true`);
         } else {
            router.push(`/order/detail/${response.orderUuid}`);
         }
      } else if (response.result.subCode == "PAYMENT_POST") {
         self.paymentPostContent = response.paymentPostContent;
         self.paymentUuid = response.orderUuid;

         handlePaymentContent(response.paymentPostContent, windowPopupElement);

         nextTickControl(self, windowPopupElement);
      } else if (response.completed == true && response.orderUuid != null) {
         router.push(`/order/detail/${response.orderUuid}`);
      }
   });
}

export {
   funcTicketDirectFlight,
   funcTicketDirectHotel,
   funcTicketPartialPayHotel,
   funcTicketDirectTransfer,
   funcTicketBaggages,
   funcTicketReissue,
   funcBalanceUpdate,
   funcTicketDirectRentACar,
   funcTicketSeats,
};
