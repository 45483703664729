import { PRIVATE_HTTP, PUBLIC_HTTP } from "./index";

const AUTOCOMPLETE = "api/autocomplete/hotel";
const SEARCH = "api/hotelsearch/search";
const DETAIL = "api/hotelsearch/detail";
const ROOMDETAIL = "api/hotelsearch/roomdetail";
const CACHESEARCH = "api/hotel/v1/search";
const ROOMAVAILABLE = "api/HotelSearch/RoomAvailability";
const SELECT = "api/hotelsearch/select";
const CHECKOUT = "api/hotelsale/checkout";
const PARTIAL_ORDER_DETAIL_CHECKOUT = "api/hotelsale/payinstallment";
const TICKETRESERVED = "api/hotelsale/ticketreserved";
const TICKETPARTIALRESERVED = "api/hotelsale/payinstallment";
const REVERSE = "api/hotelreverse/reverse";
const MANUAL_VOUCHER_ADD = "api/hotelsearch/manualvoucheradd";

export var hotelSearch = (request) => {
   const promise = PRIVATE_HTTP().post(SEARCH, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var hotelCacheSearch = (request) => {
   const promise = PRIVATE_HTTP().post(CACHESEARCH, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var hotelRoomAvailable = (request) => {
   const promise = PRIVATE_HTTP().post(ROOMAVAILABLE, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var hotelRoomDetail = (request) => {
   const promise = PRIVATE_HTTP().post(ROOMDETAIL, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var hotelAutocomplete = (request) => {
   const promise = PRIVATE_HTTP().post(AUTOCOMPLETE, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var hotelDetail = (request) => {
   const promise = PRIVATE_HTTP().post(DETAIL, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var hotelSelect = (request) => {
   const promise = PRIVATE_HTTP().post(SELECT, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var hotelCheckout = (request) => {
   const promise = PRIVATE_HTTP().post(CHECKOUT, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var partialOrderCheckout = (request) => {
   const promise = PRIVATE_HTTP().post(PARTIAL_ORDER_DETAIL_CHECKOUT, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var hotelReverse = (request) => {
   const promise = PRIVATE_HTTP().post(REVERSE, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var hotelTicketReserved = (request) => {
   const promise = PRIVATE_HTTP().post(TICKETRESERVED, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var hotelTicketPartialReserved = (request) => {
   const promise = PRIVATE_HTTP().post(TICKETPARTIALRESERVED, request);
   return promise.then((response) => {
      return response.data;
   });
};
export var getExpediaTerms = (language) => {
   console.log(language);
   const promise = PUBLIC_HTTP.get(`${process.env.VUE_APP_CDN_URL}hotel_terms/expedia/agent_terms_of_use_${language}.html`);
   return promise.then((response) => {
      return response.data;
   });
};

export var getExpediaSupplierTerms = (language) => {
   const promise = PUBLIC_HTTP.get(`${process.env.VUE_APP_CDN_URL}hotel_terms/expedia/EPS_terms_and_conditions_${language}.html`);
   return promise.then((response) => {
      return response.data;
   });
};

export var hotelManualAdd = (request) => {
   const promise = PRIVATE_HTTP().post(MANUAL_VOUCHER_ADD, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var getSearchDestinationCode = () => {
   return localStorage.getItem("searchDestinationCode");
};

export var getSearchDestinationType = () => {
   return localStorage.getItem("searchDestinationType");
};

export var getSearchDestinationName = () => {
   return localStorage.getItem("searchDestinationName");
};

export var getSearchCheckoutDate = () => {
   return localStorage.getItem("checkOut");
};

export var getSearchCheckinDate = () => {
   return localStorage.getItem("checkIn");
};

export var setSearchDestinationCode = (destinationCode) => {
   return localStorage.setItem("searchDestinationCode", destinationCode);
};

export var setSearchDestinationType = (destinationType) => {
   return localStorage.setItem("searchDestinationType", destinationType);
};

export var setSearchDestinationName = (destinationName) => {
   return localStorage.setItem("searchDestinationName", destinationName);
};

export var setSearchCheckoutDate = (checkoutDate) => {
   return localStorage.setItem("checkOut", checkoutDate);
};

export var setSearchCheckinDate = (checkinDate) => {
   return localStorage.setItem("checkIn", checkinDate);
};
