<template>
   <div class="transfer-page-wrapper">
      <Aside />
      <Main>
         <Navbar />
         <div id="transfer-search-wrapper">
            <div class="row gx-3 gx-md-2 gx-lg-3 gx-xxl-3">
               <div id="transfer-filter-column" class="col-md-1 col-lg d-none d-xxl-block"></div>

               <div class="col-lg-12 col-xl-10 col-xxl-9">
                  <Search :activeTab="5" @search-response="handlerSearchResponse" :isPackage="true" />

                  <div class="cooperation-wrapper">
                     <span>Cooperation with </span>
                     <img :src="logo" alt="Cooperation with Schimetterling" class="schimetterling-logo" />
                  </div>
               </div>

               <div class="col-md-1 col-lg d-xl-block"></div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import Search from "@/components/unitary/SearchTab.vue";
import SchimetterlingLogo from "@/assets/images/schmetterling.png";

export default {
   name: "TransferSearch",
   components: {
      Aside,
      Navbar,
      Main,
      Search,
   },
   data() {
      return {
         logo: SchimetterlingLogo,
      };
   },
};
</script>

<style lang="scss" scoped>
.transfer-page-wrapper {
   .transfer-page-inner {
      margin: auto;
      margin-top: 3rem;
   }
   span {
      // margin: 1rem 0;
      // margin-top: 0 !important;
      // margin-bottom: 1.75rem;
      // display: flex;
      // justify-content: flex-end;
      // font-size: var(--small);
      // font-weight: 600;
      // opacity: 0.65;
   }

   .cooperation-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 1.75rem;

      span {
         font-weight: 500;
         color: #053971;
         padding-right: 0.35rem;
         font-size: var(--large);
      }
      .schimetterling-logo {
         width: 150px;
         padding-bottom: 4px;
      }
   }
}
</style>
