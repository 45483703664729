<template>
   <div class="hotel-page-wrapper">
      <Aside />
      <Main>
         <Navbar />
         <!-- <button @click="facilitiesModalStatus = !facilitiesModalStatus">{{ facilitiesModalStatus }}</button> -->
         <!-- {{ result }} -->
         <transition name="fade" mode="out-in">
            <SearchInformationPopup ref="hotelInformationPopup" :label="'hotelSearchInformation'" variant="hotel" hasFooter />
         </transition>
         <FacilitiesModal :facilities="filters.facilities" v-if="facilitiesModalStatus" @close="handlerCloseHotelFacilitiesModal" />
         <!-- Hotel Search Grid -->
         <div id="hotel-search-wrapper">
            <div class="row gx-3 gx-md-2 gx-lg-3 gx-xxl-3">
               <div id="flight-filter-column" class="col-md-1 col-lg d-none d-xxl-block gts-custom-scroll" ref="hotelFilterMobile">
                  <!-- Hotel Filters -->
                  <HotelFilters :filters-data="filters" v-if="result || isFilterSearch" :filter-request="filterRequestModel" />
               </div>
               <div class="col-lg-12 col-xl-10 col-xxl-9">
                  <!-- Common Search Tab -->
                  <SearchTab :active-tab="2" />

                  <!-- Hotel Result-->
                  <!-- {{ result }} -->
                  <!-- <transition name="big data" mode="out-in"> -->

                  <!-- <ul>
                     <li>## Filter Status {{ handlerFilterStatus() }}</li>
                     <li>## SearchFrom Filter {{ isFilterSearch }}</li>
                  </ul> -->
                  <HotelSearchResult
                     v-if="result"
                     :hotel-list-prop="hotelList"
                     :reccommended-list="reccommendedList"
                     @clear-filters="handlerClearAllFilter"
                     @is-on-us="handlerOnUs"
                     :filter-search="isFilterSearch" />
                  <!-- </transition> -->
               </div>
               <div class="col-md-1 col-lg d-xl-block" v-if="!$store.state.app.clientInformations.isMobile" id="hotel-basket-mobile">
                  <Transition name="apple-right" mode="in-out">
                     <HotelBasket v-if="isBasketActive" />
                  </Transition>
               </div>
               <div class="col-md-12 d-block d-xl-none" v-if="$store.state.app.clientInformations.isMobile" id="hotel-basket-mobile">
                  <Transition name="apple-right" mode="in-out">
                     <HotelBasket v-if="isBasketActive" />
                  </Transition>
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";
import HotelFilters from "@/components/structural/hotel/search/HotelFilter.vue";
import SearchInformationPopup from "@/components/unitary/SearchInformationPopup.vue";
import SearchTab from "@/components/unitary/SearchTab.vue";
import HotelSearchResult from "@/components/structural/hotel/search/HotelSearchResult.vue";
import HotelBasket from "@/components/structural/hotel/common/HotelBasket.vue";
import FacilitiesModal from "@/components/structural/hotel/search/FacilitiesModal.vue";
import { hotelService } from "@/service/index.js";
import { globalService } from "@/service/index.js";
import { hotelListGenerator } from "@/helper/hotel.js";
// import { generatorFacilitiesModal } from "@/helper/hotel.js";
import Popup from "@/gts-popup.js";
import { priceMap } from "@/helper/hotel.js";

export default {
   name: "HotelPage",
   components: {
      Aside,
      Navbar,
      Main,
      SearchTab,
      HotelSearchResult,
      HotelBasket,
      HotelFilters,
      FacilitiesModal,
      SearchInformationPopup,
   },
   data() {
      return {
         channels: {},
         filters: {
            facilities: {},
            boardTypes: {},
            stars: {},
            starCategories: {},
            prices: {},
            recommendeds: {
               recommendeds: {
                  checked: false,
               },
            },
            discounted: {
               discounted: {
                  checked: false,
               },
            },
            isPartialPaymentExist: {
               isPartialPaymentExist: {
                  checked: false,
               },
            },
            addOn: {
               addOn: {
                  checked: false,
               },
            },
         },
         filterStatus: {
            facilities: false,
            boardTypes: false,
            stars: false,
            prices: false,
            count: false,
         },
         filterRequestModel: {
            recommended: false,
            discounted: false,
            boardTypes: [-1],
            includedFacilities: [-1],
            excludedFacilities: [],
            stars: [-1],
            starCategories: [-1],
            isPartialPaymentExist: false,
            addOn: false,

            priceFrom: 0,
            priceTo: 0,
            onUs: false,
         },
         isCountDone: false,
         hotelList: [],
         reccommendedList: [],
         isFilterSearch: false,
         filterClear: false,
         minPrice: 0,
         maxPrice: 0,

         //Checkbelow
         isBasketActive: false,
         result: false,
         facilitiesModalStatus: false,
      };
   },
   created() {
      //Create Filter
      this.handlerCreateFacilitesFilter();
      this.handlerCreateBoardTypeFilter();
      this.handlerCreateStarFilter();
      this.handlerCreatePriceFilter();
      // this.handlerCreateInformationFilter();
   },
   methods: {
      handlerOnUs() {
         this.filterRequestModel.onUs = !this.filterRequestModel.onUs;
         if (this.$store.state.hotel.isOnUsFilterSearch) {
            this.$store.commit("hotel/setOnUsFilterStatus", false);
         } else {
            if (this.$store.state.hotel.hotelStarCategory.length == 0) {
               this.$store.commit("hotel/setHotelStarCategory", -1);
            }
            this.$store.commit("hotel/setOnUsFilterStatus", true);
         }
         this.handlerFilterRequestModelGenerator();
      },
      handlerClearAllFilter() {
         this.filterClear = true;
         this.handlerFilterResetter();
         this.handlerFilterRequestModelGenerator(undefined, true);
      },
      handlerCheckAllFilter() {
         const filtersArr = [];

         const isClear = function (identifier) {
            const clearStatus = Object.keys(identifier).some((item) => {
               return identifier[item].checked;
            });

            filtersArr.push(clearStatus);
         };

         const boardTypes = this.filters.boardTypes;
         const facilities = this.filters.facilities;
         const prices = this.filters.prices;
         const stars = this.filters.stars;
         const starCategories = this.filters.starCategories;

         isClear(boardTypes);
         isClear(facilities);
         isClear(prices);
         isClear(stars);
         isClear(starCategories);

         filtersArr.push(this.filters.recommendeds.recommendeds.checked);
         filtersArr.push(this.filters.discounted.discounted.checked);
         filtersArr.push(this.filters.isPartialPaymentExist.isPartialPaymentExist.checked);
         filtersArr.push(this.filters.addOn.addOn.checked);
         filtersArr.push(this.filterRequestModel.priceFrom == 0 ? false : true);
         filtersArr.push(this.filterRequestModel.priceTo == 0 ? false : true);
         filtersArr.push(this.$store.state.hotel.hotelStarCategory.includes(-1) ? false : true);
         return filtersArr.some((item) => {
            return item == true;
         });
      },
      handlerSearchAgain() {
         const filterMode = this.handlerCheckAllFilter();
         this.isFilterSearch = filterMode;
         this.filterClear = filterMode;

         this.$store.commit("app/setIsProgressGifActive", true);
         this.result = false;

         const query = {
            searchUuid: this.$store.state.hotel.searchUuid,
            filter: this.filterRequestModel,
         };

         hotelService.hotelSearch(query).then((res) => {
            const self = this;
            // this.$store.state.hotel.searchResult = res.hotels;
            this.filterClear = false;

            if (!res.result.success) {
               Popup.result(res.result);
            } else {
               self.channels = res.channels;
               hotelListGenerator(self, res.hotels);
               hotelListGenerator(self, res.recommendedHotels);
               this.$store.commit("hotel/updateSearchResult", hotelListGenerator(self, res.hotels));
               this.$store.commit("hotel/updateSearchResultRecommendedHotel", hotelListGenerator(self, res.recommendedHotels));

               this.$store.commit("app/setIsProgressGifActive", false);
               this.result = true;
            }
         });
      },

      handlerCloseHotelFacilitiesModal(obj) {
         this.filterRequestModel.includedFacilities = obj.includedFacilities.length == 0 ? [-1] : obj.includedFacilities;
         this.facilitiesModalStatus = false;
         this.handlerSearchAgain(true);

         // this.facilitiesModalStatus = false;
      },

      handlerFilterStatusUpdate(identifier) {
         this.filterStatus[identifier] = true;
      },
      handlerFilterStatus() {
         return Object.keys(this.filterStatus).every((item) => {
            return this.filterStatus[item];
         });
      },
      handlerCreateFacilitesFilter() {
         var localeLang = this.$i18n.locale;
         localeLang = localeLang.charAt(0).toUpperCase() + localeLang.charAt(1);
         globalService
            .getFacilityList()
            .then((response) => {
               response.facilities.map((facility, index) => {
                  const facilityObj = { ...facility };
                  facilityObj.checked = false;
                  facilityObj.count = 0;
                  facilityObj.uiIndex = index;
                  facilityObj.name = facility[`name${localeLang}`];
                  facilityObj.img = `${response.baseUrl}${facility.id}.svg`;
                  facilityObj.groupName = facility[`groupName${localeLang}`];
                  facilityObj.groupId = facility.groupId;

                  this.$set(this.filters.facilities, facility.id, facilityObj);
               });

               this.$store.commit("hotel/setHotelFilterFacilitiesArr", response.facilities);
               this.$store.commit("hotel/setHotelFacilitiesBaseUrl", response.baseUrl);
               this.handlerFilterStatusUpdate("facilities");
            })
            .catch((error) => {
               console.error(error);
            });
      },
      handlerCreateBoardTypeFilter() {
         globalService
            .getBoardTypeList()
            .then((boardTypes) => {
               let localeLang = this.$i18n.locale;
               localeLang = localeLang.charAt(0).toUpperCase() + localeLang.charAt(1);
               boardTypes.map((boardtype) => {
                  const boardTypeObj = { ...boardtype };
                  boardTypeObj.checked = false;
                  boardTypeObj.count = 0;
                  boardTypeObj.name = boardtype[`name${localeLang}`];

                  this.$set(this.filters.boardTypes, boardtype.id, boardTypeObj);
               });

               this.handlerFilterStatusUpdate("boardTypes");
            })
            .catch((error) => {
               console.error("Board type listesi alınırken bir hata oluştu:", error);
            });
      },
      handlerCreateStarFilter() {
         for (let i = 1; i <= 5; i++) {
            this.$set(this.filters.stars, i, { checked: false });
         }
         this.handlerFilterStatusUpdate("stars");
      },
      handlerCreatePriceFilter() {
         const priceObj = {
            400: {
               checked: false,
               value: [0, 400],
               id: 0,
               name: "0 - 400",
               count: 0,
            },
            800: {
               checked: false,
               value: [400, 800],
               id: 400,
               name: "400 - 800",
               count: 0,
            },
            1200: {
               checked: false,
               value: [800, 1200],
               id: 800,
               name: "800 - 1200",
               count: 0,
            },
            1600: {
               checked: false,
               value: [1200, 1600],
               id: 1200,
               name: "1200 - 1600",
               count: 0,
            },
            1601: {
               checked: false,
               value: [1600, 99999],
               id: 1600,
               name: "1600+",
               count: 0,
            },
         };
         this.handlerFilterStatusUpdate("prices");
         this.$set(this.filters, "prices", priceObj);
      },
      handlerCreateInformationFilter() {
         const informationObj = {
            discounted: { checked: false, count: 0 },
            recommendeds: { checked: false, count: 0 },
            isPartialPaymentExist: { checked: false, count: 0 },
            addOn: { checked: false, count: 0 },
         };
         this.$set(this.filters.discounted, "discounted", informationObj.discounted);
         this.$set(this.filters.recommendeds, "recommendeds", informationObj.recommendeds);
         this.$set(this.filters.isPartialPaymentExist, "isPartialPaymentExist", informationObj.isPartialPaymentExist);
         this.$set(this.filters.addOn, "addOn", informationObj.addOn);
      },

      handlerBoardTypeGenerator(identifier) {
         const arr = [];
         Object.keys(this.filters[identifier]).forEach((item) => {
            if (this.filters[identifier][item].checked) {
               arr.push(this.filters[identifier][item].id);
            }
         });

         this.filterRequestModel[identifier] = arr.length == 0 ? [-1] : arr;
      },
      handlerFacilitiesGenerator() {
         const arr = [];
         Object.keys(this.filters.facilities).forEach((item) => {
            if (this.filters.facilities[item].checked) {
               arr.push(this.filters.facilities[item].id);
            }
         });

         this.filterRequestModel.includedFacilities = arr.length == 0 ? [-1] : arr;
      },
      handlerStarGenerator() {
         const arr = [];
         Object.keys(this.filters.stars).forEach((item) => {
            if (this.filters.stars[item].checked) {
               arr.push(Number(item));
            }
         });
         this.filterRequestModel.starCategories = this.$store.state.hotel.hotelStarCategory;
         this.filterRequestModel.stars = arr.length == 0 ? [-1] : arr;
      },
      handlerPriceGenerator() {
         const checkeds = [];
         Object.keys(this.filters.prices).forEach((price) => {
            if (this.filters.prices[price].checked) {
               checkeds.push(this.filters.prices[price].value);
            }
         });

         if (checkeds.length == 0) {
            this.filterRequestModel.priceFrom = 0;
            this.filterRequestModel.priceTo = 0;
         } else {
            this.filterRequestModel.priceFrom = checkeds[0][0];
            this.filterRequestModel.priceTo = checkeds[checkeds.length - 1][1];
         }
      },
      handlerPriceGeneratorSlider(priceFrom, priceTo, sendRequest) {
         this.filterRequestModel.priceFrom = priceFrom;
         this.filterRequestModel.priceTo = priceTo;

         if (sendRequest != false) {
            this.handlerFilterRequestModelGenerator(true);
         }
      },
      handlerReccommendedGenerator() {
         this.filterRequestModel.recommended = this.filters.recommendeds.recommendeds?.checked ? true : false;
         this.filterRequestModel.discounted = this.filters.discounted.discounted?.checked ? true : false;
         this.filterRequestModel.isPartialPaymentExist = this.filters.isPartialPaymentExist.isPartialPaymentExist?.checked ? true : false;
         this.filterRequestModel.addOn = this.filters.addOn.addOn?.checked ? true : false;
      },

      handlerFilterRequestModelGenerator(priceSliderEnabled) {
         this.handlerBoardTypeGenerator("boardTypes");
         this.handlerStarGenerator();
         this.handlerReccommendedGenerator();
         priceSliderEnabled ? undefined : this.handlerPriceGenerator();
         this.handlerFacilitiesGenerator();
         this.handlerSearchAgain();
      },

      handlerFilterResetter() {
         const resetter = function (identifier) {
            Object.keys(identifier).forEach((item) => {
               // identifier[item].count = 0;
               identifier[item].checked = false;
            });
         };

         const boardTypes = this.filters.boardTypes;
         const facilities = this.filters.facilities;
         const prices = this.filters.prices;
         const stars = this.filters.stars;

         this.filterRequestModel.onUs = false;
         this.$store.commit("hotel/setOnUsFilterStatus", false);
         this.$store.commit("hotel/setHotelStarCategoryClearAll");
         this.$store.commit("hotel/setHotelStarCategory", -1);
         this.filters.recommendeds.recommendeds.checked = false;
         this.filters.discounted.discounted.checked = false;
         this.filters.isPartialPaymentExist.isPartialPaymentExist.checked = false;
         this.filters.addOn.addOn.checked = false;
         this.handlerPriceGeneratorSlider(0, 0, false);

         resetter(boardTypes);
         resetter(facilities);
         resetter(prices);
         resetter(stars);
      },
      handlerCountResetter() {
         const resetter = function (identifier) {
            Object.keys(identifier).forEach((item) => {
               identifier[item].count = 0;
            });
         };

         const boardTypes = this.filters.boardTypes;
         const facilities = this.filters.facilities;
         const prices = this.filters.prices;

         resetter(boardTypes);
         resetter(facilities);
         resetter(prices);
      },

      handlerCountGenerator(obj) {
         this.handlerCountResetter();
         obj.forEach((item) => {
            item.boardTypes.forEach((boardType) => {
               this.filters.boardTypes[boardType].count++;
            });
         });
         obj.forEach((item) => {
            item.allFacilities.forEach((facility) => {
               if (facility && facility.id && this.filters.facilities[facility.id]) {
                  this.filters.facilities[facility.id].count++;
               }
            });
         });
         obj.forEach((item) => {
            const price = priceMap(item.price.totalAmount);
            this.filters.prices[price].count++;
         });
      },
      checkFilterRequestModel(newVal) {
         const baseFilterRequestModel = {
            recommended: { checked: false },
            discounted: { checked: false },
            isPartialPaymentExist: { checked: false },
            addOn: { checked: false },
            boardTypes: [-1],
            includedFacilities: [-1],
            excludedFacilities: [],
            starCategories: [-1],
            stars: [-1],
            priceFrom: 0,
            priceTo: 0,
            onUs: false,
         };

         const isDifferent = Object.keys(baseFilterRequestModel).some((key) => {
            if (Array.isArray(baseFilterRequestModel[key]) && Array.isArray(newVal[key])) {
               return baseFilterRequestModel[key].toString() !== newVal[key].toString();
            }
            return baseFilterRequestModel[key] !== newVal[key];
         });

         if (isDifferent) {
            this.$store.commit("hotel/setIsFirstSearch", false);
         } else {
            this.$store.commit("hotel/setIsFirstSearch", true);
         }
      },
   },
   watch: {
      // hotelList: {
      //    handler: function (val) {
      //       // this.handlerCountGenerator(val);
      //       console.log("hotelListWatcher", val);
      //    },
      // },
      "$store.state.hotel.searchUuid": {
         handler: function (uuid) {
            if (uuid != null && this.result) {
               this.isFilterSearch = false;
               this.filterRequestModel = {
                  recommended: { checked: false },
                  discounted: { checked: false },
                  isPartialPaymentExist: { checked: false },
                  addOn: { checked: false },
                  boardTypes: [-1],
                  includedFacilities: [-1],
                  excludedFacilities: [],
                  starCategories: [-1],
                  stars: [-1],
                  priceFrom: 0,
                  priceTo: 0,
                  onUs: false,
               };
            }
         },
         immediate: true,
      },
      "$store.state.hotel.isSearchingCheck": {
         handler: function () {
            this.checkFilterRequestModel(this.filterRequestModel);
            if (this.$store.state.hotel.isSearchingCheck == true && this.$store.state.hotel.isFirstSearch == false) {
               this.isFilterSearch = false;
               // this.handlerClearAllFilter();
               const baseFilterRequestModel = {
                  recommended: { checked: false },
                  discounted: { checked: false },
                  isPartialPaymentExist: { checked: false },
                  addOn: { checked: false },
                  boardTypes: [-1],
                  includedFacilities: [-1],
                  excludedFacilities: [],
                  starCategories: [-1],
                  stars: [-1],
                  priceFrom: 0,
                  priceTo: 0,
                  onUs: false,
               };
               this.filterRequestModel = baseFilterRequestModel;
               this.handlerFilterResetter();
            }
         },
         immediate: true,
      },
      "$store.state.hotel.searchResult": {
         handler: function (hotelList) {
            if (hotelList.length != 0) {
               this.handlerCountGenerator(hotelList);
               this.hotelList = hotelList;
            } else {
               this.hotelList = [];
            }
         },
      },
      "$store.state.hotel.searchResultRecommendedHotel": {
         handler(reccommendedList) {
            if (reccommendedList.length != 0) {
               // this.handlerCountGenerator(hotelList);
               this.reccommendedList = reccommendedList;
            } else {
               this.reccommendedList = [];
            }

            // console.log("reccommendedList", reccommendedList);
         },
         immediate: true,
      },

      "$store.state.hotel.searchResultStatus": {
         handler: function (response) {
            if (response?.success) {
               // console.log("handlerReset");
               // this.handlerResetCounts();
               this.result = true;
            } else {
               this.result = false;
            }
         },
         deep: true,
      },
      "$store.state.hotel.basketResponse"() {
         const self = this;
         if (
            Object.keys(this.$store.state.hotel.basketResponse).length != 0 &&
            Object.keys(this.$store.state.hotel.basketResponse.rooms).length > 0
         ) {
            self.isBasketActive = true;
         } else {
            self.isBasketActive = false;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.hotel-page-wrapper {
   #hotel-search-wrapper {
      margin-top: 3rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;

      @media (min-width: 992px) {
         padding-left: 1rem;
         padding-right: 1rem;
      }

      @media (min-width: 768px) {
         padding-left: 1rem;
         padding-right: 1rem;
      }
      @media (min-width: 1200px) {
         padding-left: 0.5rem;
         padding-right: 0.5rem;
      }
      @media (min-width: 1400px) {
         padding-left: 0.75rem;
         padding-right: 0.75rem;
      }
      @media (min-width: 1560px) {
         padding-left: 1rem;
         padding-right: 1rem;
      }
   }
}

#flight-filter-column {
   &.mobile-filter-active {
      display: flex !important;
      position: fixed;
      top: 0;
      bottom: 0;
      padding: 0 !important;
      z-index: 999;
      height: 100vh !important;

      ::v-deep {
         .hotel-filter-container {
            width: 100%;
            height: 100vh !important;
         }
      }
   }
}
</style>
